import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl } from 'react-native';
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
// import fetchData from '../api/api';
import { FontAwesome } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

const ContractRecords = () => {

    const navigation = useNavigation();

    const { t } = useTranslation();

    const [rechargeData, setRechargeData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [rechargePage, setRechargePage] = useState(1);
    const [money, setMoney] = useState(0);
    const [currency, setCurrency] = useState('USDT');

    // 模拟数据
    const initialRechargeData = [
    ];

    useEffect(() => {
        // 初始化数据
        setRechargePage(1)
        getRechargeApi()
    }, []);

    // api合约记录接口
    const getRechargeApi = async () => {
        try {
            const url = '/recharge/list';
            const options = {
                method: 'POST',
                body: JSON.stringify({ page: rechargePage }), // 请求体
                loading: true, // 是否显示loading弹窗
            };
            // const response = fetchData(url, options);
            // console.log(response)

            setRechargePage(rechargePage + 1)

        } catch (error) {
            console(t('fail'));
        }

        const rechargeDataList = [
            { id: 1, time: '2023-01-01', currency: 'USDT', status: '1', money: '200.00', risefall: 1, unitPrice: '100.00' },
            { id: 2, time: '2023-01-01', currency: 'USDT', status: '2', money: '200.00', risefall: 2, unitPrice: '100' },
            { id: 3, time: '2023-01-01', currency: 'USDT', status: 1, money: '200.00', risefall: 2, unitPrice: '100' },
            { id: 4, time: '2023-01-01', currency: 'USDT', status: 2, money: '200.00', risefall: 1, unitPrice: '100' },
            { id: 5, time: '2023-01-01', currency: 'USDT', status: 2, money: '200.00', risefall: 2, unitPrice: '100' },
            { id: 6, time: '2023-01-01', currency: 'USDT', status: '1', money: '200.00', risefall: 1, unitPrice: '100' },
            { id: 7, time: '2023-01-01', currency: 'USDT', status: '2', money: '200.00', risefall: 1, unitPrice: '100' },
            { id: 8, time: '2023-01-01', currency: 'USDT', status: 1, money: '200.00', risefall: 1, unitPrice: '100' },
            { id: 9, time: '2023-01-01', currency: 'USDT', status: 2, money: '200.00', risefall: 1, unitPrice: '100' },
            { id: 10, time: '2023-01-01', currency: 'USDT', status: 2, money: '200.00', risefall: 1, unitPrice: '100' },
        ];

        const updateData = rechargeDataList.map(data => {
            if (data.status == 1) {
                // return { ...data, statusText: t('unfinished') };
                return { ...data, statusText: '' };
            }
            if (data.status == 2) {
                return { ...data, statusText: t('settled') };
            }
            return data
        });

        const dataList = rechargeData.concat(updateData)
        setRechargeData(dataList);
        setLoadingMore(false);
    };

    const fetchDataList = () => {
        setRefreshing(true);
        // 模拟异步获取记录数据
        setTimeout(() => {
            getRechargeApi()
        }, 1000);
    };

    const handleRefresh = () => {
        setRefreshing(true);
        fetchDataList(); // 根据选项卡刷新数据
    };

    const handleLoadMore = () => {
        // 模拟异步加载更多数据
        setLoadingMore(true);
        setTimeout(() => {
            getRechargeApi()
        }, 1000);
    };

    const goToDetailScreen = (item) => {
        console.log(item.id)
        navigation.navigate('ContractDetail',{item:item});
    }

    const renderItem = ({ item }) => (
        <View style={styles.itemContainer}>
            <View style={styles.itemRowContainer}>
                <View style={{ flexDirection: "row" }}>
                    {item.risefall == 1 && <Text style={styles.itemTextGreen}>{t('buy')}:</Text>}
                    {item.risefall == 2 && <Text style={styles.itemTextRed}>{t('sell')}:</Text>}
                    <Text style={[item.risefall == 1 && styles.itemTextWhile, item.risefall == 2 && styles.itemTextRed]}>{item.currency}</Text>
                </View>
                <TouchableOpacity onPress={() => goToDetailScreen(item)}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.itemTextWhile}>
                            {item.statusText}
                        </Text>
                        {item.status == 2 && <FontAwesome name="chevron-right" size={12} style={[styles.itemTextWhile, { marginLeft: 5, marginTop: 4 }]} />}
                    </View>
                </TouchableOpacity>
            </View>
            <View style={styles.itemRowContainer}>
                <View style={styles.flex1}><Text style={[styles.itemTextGray]}>{t('investmentAmount')}</Text></View>
                <View style={styles.flex1}><Text style={[styles.itemTextGray]}>{t('proposalUnitPrice')}</Text></View>
                <View style={styles.flex1}><Text style={[styles.itemTextGray, styles.textRight]}>{t('proposalTime')}</Text></View>
            </View>
            <View style={styles.itemRowContainer}>
                <View style={styles.flex1}><Text style={styles.itemTextGray}>{item.money}</Text></View>
                <View style={styles.flex1}><Text style={styles.itemTextGray}>{item.unitPrice}</Text></View>
                <View style={styles.flex1}><Text style={[styles.itemTextGray, styles.textRight]}>{item.time}</Text></View>
            </View>
        </View>
    );

    return (
        <View style={styles.container}>
            <View style={{  marginHorizontal:10}}>
            <FlatList
                data={rechargeData}
                renderItem={renderItem}
                keyExtractor={(item) => item.id.toString()}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                onEndReached={!loadingMore && handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
            />
             </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemTextWhile: {
        color: commonStyle.textWhiteColor
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#2C3445',
        marginTop: 10,
        // width: '90%',
        borderRadius: 5,
        // margin: 'auto'
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    center: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    rechargeButton: {
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 35,
        width: '25%',
        marginTop: 20,
        borderRadius: 20,
        margin: 'auto',
        marginBottom: 20
    },
    whileText: {
        color: commonStyle.textWhiteColor
    },
    containerHeader: {
        backgroundColor: commonStyle.black,
        height: 100
    },
    flex1: {
        flex: 1
    },
    textRight: {
        textAlign: 'right'
    }
});

export default ContractRecords;
