import React, { useState, useEffect } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text, ActivityIndicator } from 'react-native';
import { getMarketDepth, binanceDepth, coinPrice, getHuobiPrice } from '../api/apis';
import { commonStyle } from '../components/style';
import { virtual2 } from '../components/util'
export default function Depth({ currency }) {

    const { t } = useTranslation();

    const [asks, setAsks] = useState([]);
    const [bids, setBids] = useState([]);
    const [currentPrice, setCurrentPrice] = useState('-');
    const [currentPrice2, setCurrentPrice2] = useState('-');

    // useFocusEffect(
    //     React.useCallback(() => {
    //         const intervalId = setInterval(depthdata, 8000);
    //         return () => {
    //             clearInterval(intervalId);
    //         };
    //     }, [asks, bids])
    // );

    useFocusEffect(
        React.useCallback(() => {
            const intervalId = setInterval(pricedata, 5000);
            return () => {
                clearInterval(intervalId);
            };
        }, [currency, currentPrice2])
    );

    useEffect(() => {
        console.log('currency:',currency)
        setAsks([]);
        setBids([]);

        if (Object.values(virtual2()).includes(currency)) {
            pricedata()
        }else{
            pricedata()
            // depthdata()
        }
    }, [currency]);


    function calculatePriceFluctuation(price) {
        const fluctuationPercentage = 0.1; // 上下浮动的百分比
        const fluctuation = (price * fluctuationPercentage) / 100; // 计算价格的浮动范围
        const decimalPlaces = getDecimalPlaces(price); // 获取之前价格的小数点位数
        const randomFluctuation = (Math.random() * fluctuation * 2 - fluctuation).toFixed(decimalPlaces); // 生成随机的浮动值，并保留相同的小数点位数
        return (parseFloat(price) + parseFloat(randomFluctuation)).toFixed(decimalPlaces); // 返回浮动后的价格，并保留相同的小数点位数
    }
    function getDecimalPlaces(number) {
        const match = ('' + number).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if (!match) {
            return 0;
        }
        const fractionLength = match[1] ? match[1].length : 0;
        const exponent = match[2] ? parseInt(match[2], 10) : 0;
        return Math.max(0, fractionLength - exponent);
    }
    function truncateDecimal(array) {
        var result = [];
        for (var i = 0; i < array.length; i++) {
            var value = array[i][1].toString();
            const floatValue = parseFloat(value);
            const decimalPart = (floatValue % 1).toFixed(20).substring(2);
            const truncatedValue = decimalPart.length > 6 ? floatValue.toFixed(6) : value;
            const formattedValue = parseFloat(truncatedValue).toString().replace(/(\.\d*?[1-9])0+$/, "$1");
            result.push([array[i][0], formattedValue]);
        }
        return result;
    }

    const pricedata = async () => {
        if (Object.values(virtual2()).includes(currency)) {
            // if (currentPrice > 0) {
            //     setCurrentPrice2(calculatePriceFluctuation(currentPrice));
            // } else {
              
            // }
            setCurrentPrice('-')
            await coinPrice(currency).then(response => {
                setCurrentPrice2(response?.price);
            
                if (response?.price > 0) {
                    const min = 0.0001;
                    const max = 2;
                    const asks = [];
                    const bids = [];
                    
                    for (let i = 0; i < 5; i++) {
                      const price = calculatePriceFluctuation(response?.price);
                      const randomNum = (Math.random() * (max - min) + min).toFixed(4);
                      asks.push([price, randomNum]);
                      bids.push([price, randomNum]);
                    }
                    
                    setAsks(asks);
                    setBids(bids);
                }

            })
        } else {
            setCurrentPrice2('-')
            const huobiPriceResponse = await getHuobiPrice(currency);
            if (huobiPriceResponse) {
                setCurrentPrice(huobiPriceResponse);
                if (huobiPriceResponse > 0) {
                    const min = 0.0001;
                    const max = 2;
                    const asks = [];
                    const bids = [];
                    
                    for (let i = 0; i < 5; i++) {
                      const price = calculatePriceFluctuation(huobiPriceResponse);
                      const randomNum = (Math.random() * (max - min) + min).toFixed(4);
                      asks.push([price, randomNum]);
                      bids.push([price, randomNum]);
                    }
                    
                    setAsks(asks);
                    setBids(bids);
                }
            }
        }
    }

    const depthdata = async () => {
        if (Object.values(virtual2()).includes(currency)) {
            // if (asks.length == 0) {
            //     await Promise.all([
            //         // coinPrice(currency), 
            //         binanceDepth(currency + 'USDT')])
            //         .then(([
            //             // priceResponse, 
            //             depthResponse
            //         ]) => {
            //             // if(priceResponse?.price){
            //             //     setCurrentPrice(priceResponse?.price);
            //             // }
            //             if (depthResponse.asks) {
            //                 var truncatedAsks = truncateDecimal(depthResponse.asks);
            //                 var truncatedBids = truncateDecimal(depthResponse.bids);
            //                 // console.log('truncatedAsks', truncatedAsks);
            //                 setAsks(truncatedAsks);
            //                 // console.log('truncatedBids', truncatedBids)
            //                 setBids(truncatedBids);
            //             }
            //         });
            // } else {
            //     console.log('depthdata', asks, bids)
            // }
        } else {
            const lotteryCode = (currency + 'usdt').toLowerCase();
            try {
                const [
                    marketDepthResponse,
                    // huobiPriceResponse
                ] = await Promise.all([
                    getMarketDepth(lotteryCode),
                    // getHuobiPrice(currency)
                ]);
                if (marketDepthResponse?.tick?.asks) {
                    const truncatedAsks = truncateDecimal(marketDepthResponse.tick.asks);
                    const truncatedBids = truncateDecimal(marketDepthResponse.tick.bids);
                    setAsks(truncatedAsks);
                    setBids(truncatedBids);
                }
                // if (huobiPriceResponse) {
                //     setCurrentPrice(huobiPriceResponse);
                // }
            } catch (error) {
                // // console.error('Failed to fetch data:', error);
                // }
            }
        }
    }

    return (
        <View style={styles.container}>

            {asks.length == 0 && <ActivityIndicator color={commonStyle.green} />}
            {asks.length > 0 && <View style={{ flex: 1, marginLeft: 10 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                    <View style={{ marginRight: 5 }}>
                        <Text style={{ color: '#fff', marginBottom: 5 }}>{t('price')}(USDT)</Text>
                        <View>
                            {asks.map((item, index) => (
                                <Text key={index} style={{ color: commonStyle.red, marginBottom: 5 }}>
                                    {item[0]}
                                </Text>
                            ))}
                        </View>

                        <Text style={{ color: commonStyle.red, fontSize: 18, marginVertical: 10 }}>{currentPrice2 > 0 ? currentPrice2 : currentPrice}</Text>

                        <View>
                            {bids.map((item, index) => (
                                <Text key={index} style={{ color: commonStyle.green, marginBottom: 5 }}>
                                    {item[0]}
                                </Text>
                            ))}
                        </View>
                    </View>

                    <View>
                        <Text style={{ color: '#fff', marginBottom: 5, textAlign: 'right' }}>{t('quantity')}</Text>
                        <View>
                            {asks.map((item, index) => (
                                <Text key={index} style={{ color: commonStyle.gray, marginBottom: 5 }}>
                                    {item[1]}
                                </Text>
                            ))}
                        </View>

                        <Text style={{ color: commonStyle.bgColor, fontSize: 18, marginVertical: 10 }}>-</Text>

                        <View>
                            {bids.map((item, index) => (
                                <Text key={index} style={{ color: commonStyle.gray, marginBottom: 5 }}>
                                    {item[1]}
                                </Text>
                            ))}
                        </View>
                    </View>
                </View>
            </View>}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: commonStyle.bgColor,
    },
});

