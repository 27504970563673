import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, Modal, ScrollView, Image, ActivityIndicator } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import SpliteLine from '../components/spliteLine';
import { getWebsiteInfo, getMarketDepth, getHuobiPrice, exchangesale, exchange, exchangeget, getUserbalance, getWeituoList, binanceDepth, binanceTickerPrice, coinPrice } from '../api/apis';
import { showAlert } from '../components/showAlert';
import AsyncStorage from '@react-native-community/async-storage';
import { formatTimestampToDateTime, unitconversion, navigateRequireLogin, virtual, virtual2, validateAmount } from '../components/util'
import { Entypo, AntDesign, EvilIcons } from '@expo/vector-icons';
import Toast from 'react-native-easy-toast'
import { color } from 'react-native-elements/dist/helpers';
import { Feather } from '@expo/vector-icons';

import Depth from './depth';

const App = (props) => {
    const navigation = useNavigation();
    const { t } = useTranslation();
    const toastRef = useRef(null);

    const [websiteInfoData, setWebsiteInfoData] = useState(false);

    const [currency, setCurrency] = useState('BTC');
    const [upDown, setUpDown] = useState('up');
    const [curr, setCurr] = useState('');
    const [amount, setAmount] = useState('');
    const [currentPrice, setCurrentPrice] = useState('');

    const [num, setNum] = useState('');

    const [modalVisible, setModalVisible] = useState(false);
    const [entrustType, setEntrustType] = useState(1); // 1限价委托 2市价委托
    const [transactionVolume, setTransactionVolume] = useState(0);
    const [userbalance, setUserbalance] = useState(0); // 余额
    const [currencyAmount, setCurrencyAmount] = useState(0); //币种余额

    const [chart, setChart] = useState(true);

    const [order, setOrder] = useState([]);
    const [emptyShow, setEmptyShow] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [headerRightButton, setHeaderRightButton] = useState('none');
    const [disabledButton, setDisabledButton] = useState([]);

    // React.useLayoutEffect(() => {
    //     navigation.setOptions({
    //         title: currency,
    //         headerStyle: {
    //             backgroundColor: commonStyle.bgColor,
    //             borderBottomWidth: 0
    //         },
    //         headerTitleStyle: {}
    //     });
    // }, [navigation]);

    useEffect(() => {
        init();
    }, [currency])

    // useFocusEffect(
    //     React.useCallback(() => {

    //         console.log('进入页面')
    //         console.log('props',props.route.params.currency)
    //         if(props.route.params.currency){
    //             handleCurrency(props.route.params.currency)
    //         }
    //         init()
    //         weituoList()
    //     }, [props,currency])
    // );

    useFocusEffect(
        React.useCallback(() => {
            if(props?.route?.params?.currency && props?.route?.params?.currenc != currency){
                console.log('切换币种2'+props?.route?.params?.currency)
                handleCurrency(props?.route?.params?.currency)
            }
        }, [props,currency])
    );

    const handleHeaderRightClick = () => {
        if (headerRightButton == 'none') {
            setHeaderRightButton('block')
        } else {
            setHeaderRightButton('none')
        }
    };

    React.useLayoutEffect(() => {

        navigation.setOptions({
            headerLeft: () => (
                <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => handleHeaderRightClick()}>
                    <Feather name="list" size={24} color='#fff' />
                    <Text style={{ color: '#fff', marginLeft: 5, fontSize: 18 }}>{currency}/USDT</Text>
                </TouchableOpacity>
            ),
            headerRight: () => (
                <View>
                    {chart && <TouchableOpacity onPress={() => { navigation.navigate('Chart', { currency: currency, prevName: 'ConfirmOrder' }) }}>
                        <Image style={{ width: 30, height: 30 }} source={require('../../assets/imgs/contract/tab.png')} />
                    </TouchableOpacity>}
                </View>
            ),
        });
    }, [navigation, headerRightButton,currency]);



    const init = async () => {
        console.log('init..'+currency)
        setOrder([])

        const response = await getWebsiteInfo();
        if (response.status == '1') {
            setWebsiteInfoData(response.Data)

            setCurr(response?.Data?.curr)
        }

        const lotteryCode = (currency + 'usdt').toLowerCase()

        exchangeget(lotteryCode).then(response => {
            // console.log('持仓数据')
            // console.log(response.count)
            setCurrencyAmount(response?.count)
        });



        getUserbalance().then(response => {
            if (response.status == 1) {
                setUserbalance(response?.Data?.BackData)
                // const coin = response?.Data?.other;
                // if (coin.hasOwnProperty(data.currency)) {
                //     const value = coin[data.currency];
                //     setCurrencyAmount(value)
                // }
            }
        }).catch(error => {
            // console.error(error);
        });

        if (Object.values(virtual2()).includes(currency)) {
            coinPrice(currency).then(response => {
                setAmount(response?.price)
                setCurrentPrice(response?.price);
            });
            // binanceTickerPrice(currency + 'USDT').then(response => {
            //     setAmount(response.price)
            //     setCurrentPrice(response.price);
            // });
        } else {
            const huobiPriceResponse = await getHuobiPrice(currency);
            if (huobiPriceResponse) {
                setCurrentPrice(huobiPriceResponse);
                setAmount(huobiPriceResponse)
            }

        }


        weituoList()

    };



    const weituoList = () => {
        setLoadingMore(true)
        setEmptyShow(false)
        const lotteryCode = (currency + 'usdt').toLowerCase()
        exchangeget(lotteryCode, 4).then(response => {
            setLoadingMore(false)
            if (Array.isArray(response?.data)) {
                setOrder(response?.data)
                if (response?.data.length == 0) {
                    setEmptyShow(true)
                } else {
                    setEmptyShow(false)
                }
            } else {
                setEmptyShow(true)
            }

        }).catch(error => {
            setLoadingMore(false)
            // console.error(error);
        })


        // getWeituoList().then(response => {
        //     console.log('委托单')
        //     console.log(response)
        //     if (response.status == 1) {

        //     }
        // }).catch(error => {
        //     console.log('委托单err')
        //     // console.error(error);
        // });

    };



    const handleAmountChanged = (text) => {
        if (!validateAmount(text)) {
            return;
        }
        setAmount(text);
    };

    const handleNumChanged = (text) => {
        if (!validateAmount(text)) {
            return;
        }
        setNum(text);

        setTransactionVolume(amount * text)
    };

    const handleUpDown = (direction) => {
        setNum('')
        setUpDown(direction);
    };

    const openModal = (type) => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    const handleEntrustType = (type) => {
        setEntrustType(type);
        closeModal()
    };

    const cancelOrder = (id) => {
        if (disabledButton[id]) {
            return; 
        }
        setDisabledButton(prevLoading => {
            const updatedLoading = [...prevLoading];
            updatedLoading[id] = true;
            return updatedLoading;
        });

        exchangeget('', 3, id).then(response => {
            if (response?.status == 1) {
                toastRef.current.show(t('success'));
                init();
            } else {
                toastRef.current.show(t('fail'));
            }

            setTimeout(() => {
                console.log('3');
                weituoList();
            }, 3000);
            
            setTimeout(() => {
                console.log('5');
                weituoList();
            }, 5000);
            
            setTimeout(() => {
                console.log('10');
                weituoList();
            }, 10000);

        }).finally(() => {
            setDisabledButton(prevLoading => {
                const updatedLoading = [...prevLoading];
                updatedLoading[id] = false;
                return updatedLoading;
            });
        });
    }

    const handleSubmit = () => {

        if (isSubmit) {
            return;
        }


        let betting_price;
        if (entrustType == 1) {
            betting_price = amount
        } else {
            betting_price = currentPrice
        }

        if (!(num > 0)) {
            return;
        }

        const lotteryCode = (currency + 'usdt').toLowerCase()
        const param = { lottery_code: lotteryCode, bet_type: entrustType, betting_price: betting_price, betting_count: num }
        // console.log(param)

        setIsSubmit(true)

        if (upDown == 'up') {

            // 买
            exchange(param).then(response => {

                if (response.status == 1) {
                    setNum('')
                    setIsSubmit(false)
                    setTimeout(() => {
                        toastRef.current.show(t('success'));
                    }, 2000);
                    init()

                    // navigation.navigate('BetRecord')
                } else {
                    toastRef.current.show(response.Data.msg);
                    return;
                }
            }).catch(error => {
                setIsSubmit(false)
                // toastRef.current.show('err');
                // return;
                // console.error(error);
            });

        } else {

            exchangesale(param).then(response => {

                if (response.status == 1) {
                    setNum('')
                    setIsSubmit(false)
                    setTimeout(() => {
                        toastRef.current.show(t('success'));
                    }, 2000);
                    init()
                    //navigation.navigate('BetRecord')
                } else {
                    setIsSubmit(false)
                    toastRef.current.show(response.Data.msg);
                    return;
                }
            }).catch(error => {
                setIsSubmit(false)
            });
        }


        setTimeout(() => {
            console.log('3');
            weituoList();
        }, 3000);
        
        setTimeout(() => {
            console.log('5');
            weituoList();
        }, 5000);
        
        setTimeout(() => {
            console.log('10');
            weituoList();
        }, 10000);
    };



    const goToConfirmOrderScreen = (direction) => {

        const route = {
            name: 'ConfirmOrder2',
            params: { currency: currency, upDown: direction },
        };
        navigateRequireLogin(navigation, route);

    };

    const handleCurrency = (currencyVal) => {

        if(props?.route?.params?.currency){
            props.route.params.currency = false
        }

        setHeaderRightButton('none')
        setCurrency(currencyVal)
        console.log('切换币种'+currencyVal)
        if (Object.values(virtual2()).includes(currencyVal)) {
            setChart(false)
        } else {
            setChart(true)
        }
        setNum('')
        // setAmount('')
        // setCurrentPrice('')
        // setOrder([])

    }

    const BottomButtons = () => {
        return (
            <View style={styles.bottomContainer}>
                <TouchableOpacity style={styles.button} onPress={() => goToConfirmOrderScreen('up')}>
                    <View style={styles.buttonLeft}>
                        <Text style={styles.bottomText}>{t('buyUp')}</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button]} onPress={() => goToConfirmOrderScreen('down')}>
                    <View style={styles.buttonRight}>
                        <Text style={styles.bottomText}>{t('buyDown')}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    };

    return (
        <View style={styles.container}>
{/* 
            <View style={{ display: headerRightButton, backgroundColor: 'rgba(18, 20, 32, 1)', borderRadius: 3, borderWidth: 1, borderColor: 'gray', width: 305, position: 'absolute', top: 0, left: 5, zIndex: 999 }}>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}> */}

                <ScrollView
                        showsVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={false}
                        style={{ flexDirection: 'row', flexWrap: 'wrap',display: headerRightButton, top: 0, left: 5, zIndex: 999, }}
                    >
                    {Object.entries(virtual()).map(([symbol, name]) => 

                    // (websiteInfoData && (websiteInfoData.hasOwnProperty(`COIN_${symbol}`) && websiteInfoData[`COIN_${symbol}`] == 1) || !websiteInfoData.hasOwnProperty(`COIN_${symbol}`)) &&

                    (
                        <View key={name}>
                            <TouchableOpacity onPress={() => handleCurrency(symbol)}>
                                <View style={{ width: 100 }}>
                                    <Text style={{ textAlign: 'center', color: '#fff', marginVertical: 10 }}>{symbol}/USDT</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    ))}

                    {/* {Object.entries(virtual2()).map(([symbol, name]) => 
                    (websiteInfoData && (websiteInfoData.hasOwnProperty(`COIN_${symbol}`) && websiteInfoData[`COIN_${symbol}`] == 1) || !websiteInfoData.hasOwnProperty(`COIN_${symbol}`)) &&
                    (
                        <View key={name}>
                            <TouchableOpacity onPress={() => handleCurrency(symbol)}>
                                <View style={{ width: 100 }}>
                                    <Text style={{ textAlign: 'center', color: '#fff', marginVertical: 10 }}>{symbol}/USDT</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    ))} */}

                </ScrollView>
                {/* </View>
            </View> */}

            {headerRightButton == 'none' && <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{ width: '98%', }}
            >

                <View style={{ marginTop: 5 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                        <View style={{ flex: 1 }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <TouchableOpacity onPress={() => handleUpDown('up')} style={{ flex: 1 }}>
                                    <Text style={{ textAlign: 'center', color: '#fff', backgroundColor: commonStyle.green, ...styles.button }}>
                                        {t('buy')}
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => handleUpDown('down')} style={{ flex: 1 }}>
                                    <Text style={{ textAlign: 'center', color: '#fff', backgroundColor: commonStyle.red, ...styles.button }}>
                                        {t('sell')}
                                    </Text>
                                </TouchableOpacity>
                            </View>

                            <View>
                                <TouchableOpacity onPress={openModal}>
                                    <View style={styles.dropdownContainer}>
                                        {entrustType == 1 && <Text style={styles.dropdownText}>{t('limitOrder')}</Text>}
                                        {entrustType == 0 && <Text style={styles.dropdownText}>{t('marketOrder')}</Text>}
                                        <AntDesign name="down" size={16} color="#707070" />
                                        {/* <Text style={styles.dropdownIcon}>∨</Text> */}
                                    </View>
                                </TouchableOpacity>
                                {entrustType == 1 &&
                                    <TextInput
                                        style={styles.input}
                                        placeholder={t('amount')}
                                        onChangeText={handleAmountChanged}
                                        keyboardType="numeric"
                                        value={amount && amount.toString()}
                                        placeholderTextColor="#707070"
                                    />}

                                <TextInput
                                    style={styles.input}
                                    placeholder={t('quantity')}
                                    onChangeText={handleNumChanged}
                                    keyboardType="numeric"
                                    value={num && num.toString()}
                                    placeholderTextColor="#707070"
                                />


                                {/* <Text style={{ color: '#fff', marginTop: 10 }}>{t('accountBalance')}: {upDown == 'up' ? unitconversion(userbalance, curr) + ' ' + curr : currencyAmount + ' ' + currency}</Text> */}

                                {upDown == 'up' && <Text style={{ color: '#fff', marginTop: 10, fontSize: 13 }}>{t('accountBalance')}: {unitconversion(userbalance, curr)} {curr}</Text>}
                                {upDown != 'up' && <Text style={{ color: '#fff', marginTop: 10, fontSize: 13 }}>{t('position')}: {currencyAmount} {currency}</Text>}

                                <Text style={{ color: '#fff', marginTop: 10, fontSize: 13 }}>{t('transactionVolume')}: {transactionVolume.toFixed(4)} USDT</Text>

                                <TouchableOpacity onPress={handleSubmit}>
                                    <Text style={{ ...styles.button, backgroundColor: upDown === 'up' ? commonStyle.green : commonStyle.red, marginTop: 10, textAlign: 'center', color: '#fff' }}>
                                        {upDown === 'up' ? t('buy') : t('sell')}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                        <Depth currency={currency} />



                    </View>

                    <View style={{ marginBottom: 10, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ color: '#fff' }}>{t('entrustOrder')}</Text>
                            <TouchableOpacity style={{ flexDirection: 'row', marginLeft: 5, marginTop: 2 }} onPress={() => weituoList()}>
                                <AntDesign name="reload1" size={14} color="#fff" />
                            </TouchableOpacity>
                        </View>
                        <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => { navigation.navigate('EntrustOrder', { currency: currency }) }}>
                            <EvilIcons name="navicon" size={21} color="#fff" />
                            <Text style={{ color: '#fff' }}>{t('history')}</Text>
                        </TouchableOpacity>



                    </View>

                    <SpliteLine />


                    <View style={{}}>

                        {/* {loadingMore && <ActivityIndicator color={commonStyle.green} style={{ marginTop: 20 }} />} */}

                        {emptyShow && <View>
                            <Text style={{ color: commonStyle.textGrayColor, textAlign: 'center', marginTop: 20 }}>{t('noData')}</Text>
                        </View>
                        }

                        {!emptyShow && order.map((item, index) => (
                            <View key={index} style={styles.itemContainer}>
                                <View style={styles.itemRowContainer}>
                                    <View style={{ flexDirection: 'row' }}>

                                        <Text style={styles.itemTextGreen}>{item.order_number}</Text>
                                    </View>
                                    <View>
                                        {item.buy_status == 0 &&
                                            <Text style={styles.itemTextYellow}>{t('inProgress')}</Text>
                                        }
                                        {item.buy_status == 1 &&
                                            <Text style={styles.itemTextGreen}>{t('tradeSuccessful')}</Text>
                                        }
                                        {item.buy_status == 2 &&
                                            <Text style={styles.itemTextRed}>{t('canceled')}</Text>
                                        }
                                    </View>
                                </View>
                                <View style={styles.itemRowContainer}>

                                    <View>
                                        <TouchableOpacity>
                                            <Text style={styles.itemTextGreen}>
                                                {formatTimestampToDateTime(item.buy_time / 1000)}
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View>
                                        {item.buy_sale == 0 &&
                                            <Text style={[styles.itemTextGreen, { marginRight: 5 }]}>{t('buy')}</Text>
                                        }
                                        {item.buy_sale == 1 &&
                                            <Text style={[styles.itemTextRed, { marginRight: 5 }]}>{t('sell')}</Text>
                                        }

                                    </View>
                                </View>

                                <View style={styles.itemRowContainer}>
                                    <View>
                                        <Text style={styles.itemTextGreen}>{t('quantity')}: {item.buy_number}</Text>

                                    </View>
                                    <View>
                                        {Number(item.buy_price) > 0 && <Text style={styles.itemTextRed}>{item.buy_price}</Text>}

                                    </View>
                                </View>

                                <View style={styles.itemRowContainer}>
                                    <View>
                                        {Number(item.ord_price) > 0 &&
                                            <View style={{ flexDirection: 'row' }}>
                                                <Text style={styles.itemTextGreen}>{t('ordPrice')}: </Text>
                                                <Text style={styles.itemTextRed}>{item.ord_price}</Text>
                                            </View>
                                        }
                                    </View>
                                    <View>
                                        {item.buy_status == 0 &&
                                            <TouchableOpacity  disabled={disabledButton[item.cd_id]} onPress={() => { cancelOrder(item.cd_id) }}>
                                                <Text style={{ textAlign: 'center', color: '#fff', backgroundColor: commonStyle.red, paddingHorizontal: 8, paddingVertical: 3, borderRadius: 3 }}>
                                                    {t('cancel')}
                                                </Text>
                                            </TouchableOpacity>
                                        }
                                    </View>

                                </View>
                            </View>
                        ))}


                    </View>



                </View>


                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                >
                    <View style={styles.modalContainer}>
                        <View style={styles.modalContent}>
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <AntDesign name="down" onPress={() => { closeModal() }}
                                    size={24} color="#707070" style={styles.modelDown}
                                />
                            </View>

                            <ScrollView
                                showsVerticalScrollIndicator={false}
                                showsHorizontalScrollIndicator={false}
                            >
                                <View>

                                    <TouchableOpacity onPress={() => handleEntrustType(1)}>
                                        <Text style={[styles.modalText]}>{t('limitOrder')}</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity onPress={() => handleEntrustType(0)} >
                                        <Text style={[styles.modalText]}>{t('marketOrder')}</Text>
                                    </TouchableOpacity>

                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </Modal>

                <Toast
                    ref={toastRef}
                    style={{ backgroundColor: 'gray' }}
                    position='top'
                    positionValue={200}
                    textStyle={{ color: '#fff' }}
                />
            </ScrollView > }

            {/* <BottomButtons /> */}

        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center',
    },
    button: {
        paddingVertical: 8,
        paddingHorizontal: 2,
        borderRadius: 5,
        margin: 2
        // marginRight: 5
    },
    dropdownContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 40,
        marginTop: 10,
        color: commonStyle.green,
        backgroundColor: '#1B1D2A',
        padding: 5
    },
    dropdownText: {
        color: 'gray'
    },
    dropdownIcon: {
        color: 'gray',
        fontSize: 12
    },
    input: {
        marginTop: 5,
        color: commonStyle.green,
        backgroundColor: '#1B1D2A',
        padding: 5
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#1B1D29',
        marginTop: 5,
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextYellow: {
        color: commonStyle.yellow,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '50%'
    },
    closeButton: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fff',
        marginTop: 16,
        textAlign: 'center',
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
    bottomContainer: {
        position: 'absolute',
        bottom: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: commonStyle.bgColor,
    },
    bottomText: {
        textAlign: 'center',
        color: commonStyle.textWhiteColor,
    },
    buttonLeft: {
        backgroundColor: commonStyle.green,
        paddingVertical: 10,
        // width: '80%',
        justifyContent: "center",
        // paddingHorizontal: 60,
        borderRadius: 5,
        padding: 40
    },
    buttonRight: {
        backgroundColor: '#F5465C',
        // width: '80%',
        paddingVertical: 10,
        // paddingHorizontal: 60,
        borderRadius: 5,
        padding: 40
    },
});

export default App;
