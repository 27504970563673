import { Platform } from 'react-native';
import 'intl-pluralrules'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en.json'
import cn from './languages/cn.json'
import es from './languages/es.json'
import pt from './languages/pt.json'
import vn from './languages/vn.json'
import id from './languages/id.json'
import ru from './languages/ru.json'
import tr from './languages/tr.json'
import ar from './languages/ar.json'
import hi from './languages/hi.json'
import ja from './languages/ja.json'
import ko from './languages/ko.json'
import th from './languages/th.json'
import AsyncStorage from '@react-native-community/async-storage';
import * as Localization from 'expo-localization';
import { commonLang } from '../components/config';

// Constants
const DEFAULT_LANGUAGE = 'en';
const LANGUAGE_KEY = 'language';

const languageMappings = commonLang.languageMappings;

const resources = {
    en: { translation: en },  // 中文
    cn: { translation: cn },  // 英语
    es: { translation: es },  // 西班牙
    pt: { translation: pt },  // 葡萄牙
    vn: { translation: vn },  // 越南
    id: { translation: id },  // 印尼
    ru: { translation: ru },  // 俄罗斯
    tr: { translation: tr },  // 土耳其
    ar: { translation: ar },  // 阿拉伯
    hi: { translation: hi },  // 印地
    ja: { translation: ja },  // 日语
    ko: { translation: ko },  // 韩语
    th: { translation: th },  // 泰语
};

// 存储数据
const storeData = async (key, value) => {
    try {
        await AsyncStorage.setItem(key, value);
        // console.log('数据已存储');
    } catch (error) {
        // console.log('存储数据时出错:', error);
    }
};

// 获取数据
const getData = async (key) => {

    console.log('客服端语言',Localization.locale)
    try {
        let value = await AsyncStorage.getItem(key) || languageMappings[Localization.locale] || DEFAULT_LANGUAGE;
        storeData(LANGUAGE_KEY, value);

        i18n.use(initReactI18next).init({
            resources,
            lng: value,
            fallbackLng: DEFAULT_LANGUAGE,
            interpolation: { escapeValue: false },
        });
    } catch (error) {
        console.error('Error retrieving data:', error);
    }
};


// 从本地存储获取语言，如果没有则使用默认语言
// const savedLanguage = localStorage.getItem('language');
// const savedLanguage = await AsyncStorageUtil.retrieveData('language');

const savedLanguage = 'en'

const defaultLanguage = 'en'; // 默认语言
const initialLanguage = savedLanguage || defaultLanguage;

// 初始化 i18n
i18n.use(initReactI18next).init({
    resources,
    lng: initialLanguage, // 设置语言为初始语言
    fallbackLng: defaultLanguage, // 如果找不到指定语言的翻译，使用默认语言
    interpolation: {
        escapeValue: false,
    },
});

getData('language')

// 在语言切换时保存用户选择的语言到本地存储
i18n.on('languageChanged', (lng) => {
    // localStorage.setItem('language', lng);
    // AsyncStorageUtil.storeData('language', lng);
    storeData('language', lng)
});

export default i18n;
