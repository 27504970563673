import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, StatusBar, ActivityIndicator, View, Text, Dimensions, ScrollView, TouchableOpacity, Platform, Linking, BackHandler, Alert } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next';
import { commonStyle } from './src/components/style';
import { navigationRef } from './src/components/navigationRef';
import i18next from './src/i18n/i18n';
import AsyncStorage from '@react-native-community/async-storage';

import Home from './src/pages/home';
import Customer from './src/pages/customer'
import Chart from './src/pages/chart';
// import ExchangeDefault from './src/pages/exchangeDefault';
import Defi from './src/pages/defi';
import Publicize from './src/pages/publicize';
import My from './src/pages/my';
import Login from './src/pages/login';
import Register from './src/pages/register';
import ArticleDetail from './src/pages/articleDetail';
import Settings from './src/pages/settings';
import Language from './src/pages/language';
import FinancialPassword from './src/pages/financialPassword';
import PaymentMethod from './src/pages/paymentMethod';
import AddBankCard from './src/pages/addBankCard';
import ExtractCurrency from './src/pages/extractCurrency';
import Wallet from './src/pages/wallet';
import Exchange from './src/pages/exchange';
import Option from './src/pages/option';
import RechargeRecord from './src/pages/rechargeRecord';
import Balance from './src/pages/balance';
import ContractRecords from './src/pages/contractRecords';
import ContractDetail from './src/pages/contractDetail';
import Deposit from './src/pages/deposit';
import Demo from './src/pages/demo';
import Pay from './src/pages/pay';
import SecurityQuestion from './src/pages/securityQuestion';
import EditPassword from './src/pages/editPassword';
import ForgetPwd from './src/pages/forgetPwd';
import MessageNotifications from './src/pages/messageNotifications';
import Message from './src/pages/message';
import MessageDetail from './src/pages/messageDetail';
import NetWork from './src/pages/netWork';
import BillingRecord from './src/pages/billingRecord';
import WithdrawalRecords from './src/pages/withdrawalRecords';
import BetRecord from './src/pages/betRecord';
import PrimaryAuthentication from './src/pages/primaryAuthentication';
import AdvancedAuthentication from './src/pages/advancedAuthentication';
import RechargeChannel from './src/pages/rechargeChannel';
import RechargeWayUnion from './src/pages/rechargeWayUnion';
import ThirdpartyPay from './src/pages/thirdpartyPay';
import BetRecordDetail from './src/pages/betRecordDetail';
import Mining from './src/pages/mining';
import MiningRecord from './src/pages/miningRecord';
import Payment from './src/pages/payment';
import About from './src/pages/about';
import LuckyWheel from './src/pages/luckyWheel';
import Loan from './src/pages/loan';
import Repayment from './src/pages/loanRepayment';
import QuickExchange from './src/pages/quickExchange';
import QuickExchangeRecord from './src/pages/quickExchangeRecord';
import EntrustOrder from './src/pages/entrustOrder';
import Contract from './src/pages/contract';
import ContractOrder from './src/pages/contractOrder';


import config from './config';
import appJson from './app.json'; // 请根据 app.json 文件的路径进行相应的更改
import { getWebsiteInfo, newmessage } from './src/api/apis';
import { commonConfig } from './src/components/config';
import { SafeAreaProvider } from 'react-native-safe-area-context';


const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator();

export default function App() {

  const { t } = useTranslation();

  const [isFirstInstall, setIsFirstInstall] = useState(null);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [agency, setAgency] = useState(0);

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;

  const images = [
    require('./assets/guide.png'),
    require('./assets/guide2.png'),
    require('./assets/guide3.png'),
  ];


  // useEffect(() => {
  //   const backAction = () => {
  //     return true;
  //   };
  //   const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);
  //   return () => backHandler.remove();
  // }, []);

  useEffect(() => {

    websiteInfo()

    if (Platform.OS == 'web') {

      // 初始化 Facebook SDK
      !function (f, b, e, v, n, t, s) {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = []; t = b.createElement(e); t.async = !0;
        t.src = v; s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      }(window, document, 'script',
        'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', config.facebookPixelID);
      fbq('track', 'Home');

      // 初始化 Google Analytics
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date();
        a = s.createElement(o),
          m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m)
      })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
      ga('create', config.googleAnalyticsID, 'auto');
      ga('send', 'pageview', '/home');

      // 初始化 TikTok Pixel
      !function (w, d, t) {
        var ttq = w.ttq || [];
        var s = d.createElement(t);
        s.async = true;
        s.src = "https://analytics.tiktok.com/i18n/pixel/sdk.js";
        s.onload = function () {
          if (ttq.load) {
            ttq.load(config.tiktokPixelID);
          }
        };
        var firstScript = d.getElementsByTagName(t)[0];
        firstScript.parentNode.insertBefore(s, firstScript);
        w.ttq = ttq;
      }(window, document, "script");

    } else {
      // testClear()
      checkVersion();
      checkFirstInstall();
    }
    
   
  }, []);


  const testClear = async () => {
    await AsyncStorage.setItem('isFirstInstall', '');
    setIsFirstInstall(false);
  }

  const handleScroll = (event) => {
    const contentOffset = event.nativeEvent.contentOffset;
    const imageIndex = Math.round(contentOffset.x / windowWidth);
    setCurrentImageIndex(imageIndex);
  };


  const websiteInfo = async () => {
    const response = await getWebsiteInfo();
    if (response.status == '1') {
      setAgency(response.Data.Agency)
    }
  }

  const checkVersion = async () => {
    const version = appJson.expo.version;
    console.log('当前版本', version);
    const websiteInfo = await getWebsiteInfo();
    if (websiteInfo.status == '1') {
      const domain = websiteInfo.Data.default_domain
      const response = await newmessage();
      if (response.status == '1') {
        const data = response.Data;
        const isIOS = Platform.OS === 'ios';
        const minVersionRequired = isIOS ? data.MinIOSrefresh : data.MinAndroidrefresh;

        // 如果当前版本低于服务器指定的最小版本，提示用户更新
        if (version < minVersionRequired) {
          Alert.alert(
            t('updatePrompt'),
            t('latestVersion'),
            [
              {
                text: t('update2'), onPress: () => {
                  Linking.openURL(websiteInfo.Data.alAndroid)
                    .then(() => {
                      // 延迟退出，给用户一些时间查看浏览器中的内容
                      setTimeout(() => BackHandler.exitApp(), 1500);
                    })
                    .catch((err) => console.error('An error occurred', err));
                }
              },
              { text: t('out'), onPress: () => BackHandler.exitApp() },
            ],
            { cancelable: false },
          );
        }
      }
    }
  };

  const checkFirstInstall = async () => {
    try {
      // 检查是否已经安装过应用
      const value = await AsyncStorage.getItem('isFirstInstall');
      if (value === null) {
        // 如果是第一次安装，将状态设置为 true
        await AsyncStorage.setItem('isFirstInstall', 'true');
        setIsFirstInstall(true);
      } else {
        setIsFirstInstall(false);
      }
    } catch (error) {
      console.error('Error checking first install:', error);
      setIsFirstInstall(false);
    }
  };


  const iconSources = {
    Home: {
      selected: require('./assets/imgs/tabs/home_select.png'),
      unselected: require('./assets/imgs/tabs/home.png'),
    },
    Customer: {
      selected: require('./assets/imgs/tabs/customer_select.png'),
      unselected: require('./assets/imgs/tabs/customer.png'),
    },
    Defi: {
      selected: require('./assets/imgs/tabs/defi_select.png'),
      unselected: require('./assets/imgs/tabs/defi.png'),
    },
    Exchange: {
      selected: require('./assets/imgs/tabs/exchange_select.png'),
      unselected: require('./assets/imgs/tabs/exchange.png'),
    },
    Contract: {
      selected: require('./assets/imgs/tabs/contract_select.png'),
      unselected: require('./assets/imgs/tabs/contract.png'),
    },
    Publicize: {
      selected: require('./assets/imgs/tabs/publicize_select.png'),
      unselected: require('./assets/imgs/tabs/publicize.png'),
    },
    My: {
      selected: require('./assets/imgs/tabs/my_select.png'),
      unselected: require('./assets/imgs/tabs/my.png'),
    },
    Wallet: {
      selected: require('./assets/imgs/tabs/wallet_select.png'),
      unselected: require('./assets/imgs/tabs/wallet.png'),
    },
    Mining: {
      selected: require('./assets/imgs/tabs/mining_select.png'),
      unselected: require('./assets/imgs/tabs/mining.png'),
    }
  };

  const screenOptions = ({ route }) => {
    if (!iconSources[route.name]) {
      return
    }
    const { selected, unselected } = iconSources[route.name];
    const iconSource = route.state?.index === 0 ? selected : unselected;


    return {
      tabBarStyle: {
        backgroundColor: commonStyle.bgColor,
        borderTopWidth: 0,
      },
      headerStyle: styles.headerStyle,
      headerTintColor: styles.whiteColor.color,
      headerTitleStyle: styles.headerTitleStyle,
      headerTitleAlign: 'center',
      tabBarIcon: ({ focused, color, size }) => (
        <Image source={iconSource}
          style={[route.name == 'Publicize' ? styles.tabBarIcon2 : styles.tabBarIcon, 
          route.name == 'My' ? styles.tabBarIcon3 : '', 
          route.name == 'Mining' ? styles.tabBarIcon4 : '',
          route.name == 'Wallet' ? styles.tabBarIcon4 : '',
          route.name == 'Publicize' ? styles.tabBarIcon : '',
          
          { tintColor: focused ? '#2CF793' : color }]} />
      ),
      title: t(route.name),
      tabBarActiveTintColor: '#2CF793',
      tabBarInactiveTintColor: '#fff',
    };
  };

  const headerOptions = {
    headerStyle: styles.headerStyle,
    headerTintColor: styles.whiteColor.color,
    headerTitleStyle: styles.headerTitleStyle,
    headerTitleAlign: 'center',
  };


  const MainNav = () => (
    <Tab.Navigator initialRouteName="Home" screenOptions={screenOptions}>
      {/* 主页 */}
      <Tab.Screen name="Home" component={Home} options={{ title: t('home'), headerShown: false }} />
      {/* 挖矿 */}
      {/* <Tab.Screen name="Mining" component={Mining} options={{ title: t('mining'), headerShown: true }} /> */}
      {/* defi */}
      {/* <Tab.Screen name="Defi" component={Defi} options={{ title: t('deFi') }} /> */}
      {/* 客服 */}
      {/* <Tab.Screen name="Customer" component={Customer} options={{ title: t('customer') }} /> */}
      {/* 交易 */}
      <Tab.Screen name="Exchange" component={Exchange} options={{ title: t('exchange') }} />
      {/* 合约 */}
      <Tab.Screen name="Contract" component={Contract} options={{ title: t('contract'),  }} />

      {/* <Tab.Screen name="ExchangeDefault" component={ExchangeDefault} options={{ title: t('exchange') }} /> */}
      {/* 推广 */}
      {agency == 1 && <Tab.Screen name="Publicize" component={Publicize} options={{ title: t('publicize') }} /> }
      {/* 钱包 */}
      <Tab.Screen name="Wallet" component={Wallet} options={{ title: t('wallet') }} /> 
      {/* 我的 */}
      <Tab.Screen name="My" component={My} options={{ title: t('me'), headerShown: false }} />

      {/* <Tab.Screen name="LuckyWheel" component={LuckyWheel} options={{ title: t('luckyWheel') }} />

       */}

      {/* <Tab.Screen name="Demo" component={Demo} options={{ title: 'demo' }} /> */}
      {/* <Tab.Screen name="Pay" component={Pay} options={{ title: 'pay' }} /> */}

    </Tab.Navigator>
  );

  // if (isFirstInstall === null) {
  //   return (
  //     <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
  //       <ActivityIndicator size="large" />
  //     </View>
  //   );
  // }

  // 如果是第一次安装，显示启动动画
  if (isFirstInstall && Platform.OS !== 'web') {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {/* <Image source={require('.assets/your-splash-image.png')} /> */}
        <View style={styles.container}>
          <ScrollView
            horizontal
            pagingEnabled
            showsHorizontalScrollIndicator={false}
            onScroll={handleScroll}
          >
            {images.map((image, index) => (
              <View key={index} style={{ width: windowWidth, height: windowHeight, alignItems: 'center', justifyContent: 'center' }}>
                <Image key={index} source={image} style={styles.image} />
                {index == 0 &&
                  <View>
                    <Text style={{ color: '#fff', textAlign: 'center' }}>{t('guide1')}</Text>
                    <Text style={{ color: '#fff', marginTop: 15 }}>{t('guide2')}</Text>
                  </View>
                }
                {index == 1 &&
                  <View>
                    <Text style={{ color: '#fff', textAlign: 'center' }}>{t('guide3')}</Text>
                    <Text style={{ color: '#fff', marginTop: 15 }}>{t('guide4')}</Text>
                  </View>
                }
                {index == 2 &&
                  <View>
                    <Text style={{ color: '#fff', textAlign: 'center' }}>{t('guide5')}</Text>
                    <Text style={{ color: '#fff', marginTop: 15 }}>{t('guide6')}</Text>
                  </View>
                }
                <View style={styles.indicatorContainer}>
                  {images.map((_, index) => (
                    <View
                      key={index}
                      style={[
                        styles.indicator,
                        index === currentImageIndex && styles.activeIndicator,
                      ]}
                    />
                  ))}
                </View>
                {/* {index === images.length - 1 && ( */}
                <TouchableOpacity style={styles.homeButton} onPress={() => {
                  setIsFirstInstall(false);
                }}>
                  <Text style={styles.homeButtonText}>{t('experienceNow')}</Text>
                </TouchableOpacity>
                {/* )} */}
              </View>
            ))}
          </ScrollView>

        </View>
      </View>
    );
  }

  return (
    <><StatusBar backgroundColor={commonStyle.bgColor} />
      <SafeAreaProvider style={{ maxWidth: 500, marginHorizontal: 'auto' }}>

        <NavigationContainer ref={navigationRef}>
          <Stack.Navigator>
            <Stack.Screen
              name="MainNav"
              component={MainNav}
              options={{ headerShown: false }} />
              
            <Stack.Screen
              name="Chart"
              component={Chart}
              options={{ ...headerOptions, title: t('') }} />
            <Stack.Screen
              name="Publicize"
              component={Publicize}
              options={{ ...headerOptions, title: t('publicize') }} />
            <Stack.Screen
              name="Home"
              component={Home}
              options={{ ...headerOptions, title: t('home') }} />
            <Stack.Screen
              name="Register"
              component={Register}
              options={{ ...headerOptions, title: t('register') }} />
            <Stack.Screen
              name="Login"
              component={Login}
              options={{ ...headerOptions, headerShown: false, title: t('login') }} />
            <Stack.Screen
              name="ArticleDetail"
              component={ArticleDetail}
              options={{ ...headerOptions, title: '' }} //文章详情
            />
            <Stack.Screen name="RechargeRecord" component={RechargeRecord} options={{
              ...headerOptions,
              title: t('rechargeRecord') //充值记录
            }} />
            <Stack.Screen name="Balance" component={Balance} options={{
              ...headerOptions,
              title: t('myBalance') //我的余额
            }} />
            <Stack.Screen name="ContractRecords" component={ContractRecords} options={{
              ...headerOptions,
              title: t('contractRecords') //合约记录
            }} />
            <Stack.Screen name="ContractDetail" component={ContractDetail} options={{
              ...headerOptions,
              title: t('contractDetail'), //合约详情
            }} />
            <Stack.Screen name="Language" component={Language}
              options={{
                ...headerOptions, title: t('language'), //语言
              }} />
            {/* <Stack.Screen name="ConfirmOrder" component={ConfirmOrder} options={{
              ...headerOptions,
              title: t(''), //确认下单
            }} /> */}
             <Stack.Screen name="Option" component={Option} options={{
              ...headerOptions,
              title: t('option'), //期权
            }} />

            {/* <Stack.Screen name="Wallet" component={Wallet} options={{
              ...headerOptions,
              title: t('wallet'), //钱包
            }} /> */}
            <Stack.Screen name="ExtractCurrency" component={ExtractCurrency} options={{
              ...headerOptions,
              title: t('withdrawal'), //提币
            }} />
            <Stack.Screen name="PaymentMethod" component={PaymentMethod} options={{
              ...headerOptions,
              title: t('paymentMethod'), //支付方式
            }} />
            <Stack.Screen name="AddBankCard" component={AddBankCard} options={{
              ...headerOptions,
              title: t('addBankCard'), //添加银行卡
            }} />
            <Stack.Screen name="FinancialPassword" component={FinancialPassword} options={{
              ...headerOptions,
              title: t('financialPassword'), //设置资金密码
            }} />
            <Stack.Screen name="Settings" component={Settings} options={{
              ...headerOptions,
              title: t('settings'), //设置
            }} />
            <Stack.Screen name="MessageNotifications" component={MessageNotifications} options={{
              ...headerOptions,
              title: t('messageNotifications'), //消息通知
            }} />
            <Stack.Screen name="Message" component={Message} options={{
              ...headerOptions,
              title: t('messageNotifications'), //消息通知列表
            }} />
            <Stack.Screen name="MessageDetail" component={MessageDetail} options={{
              ...headerOptions,
              title: t(''), //消息通知详情
            }} />
            <Stack.Screen name="SecurityQuestion" component={SecurityQuestion} options={{
              ...headerOptions,
              title: t('securityQuestion'), //设置密码保护
            }} />
            <Stack.Screen name="EditPassword" component={EditPassword} options={{
              ...headerOptions,
              title: t('update') + t('loginPassword'), //修改密码
            }} />
            <Stack.Screen name="ForgetPwd" component={ForgetPwd} options={{
              ...headerOptions,
              title: t('forgotPassword'), //忘记密码
            }} />
            <Stack.Screen name="NetWork" component={NetWork} options={{
              headerShown: false, //网络异常
            }} />
            <Stack.Screen name="BillingRecord" component={BillingRecord} options={{
              ...headerOptions,
              title: t('bill'), //账户明细
            }} />
            <Stack.Screen name="WithdrawalRecords" component={WithdrawalRecords} options={{
              ...headerOptions,
              title: t('withdrawal') + t('record'), //提现记录
            }} />
            <Stack.Screen name="BetRecord" component={BetRecord} options={{
              ...headerOptions,
              title: t('betting') + t('record'), //投注记录
            }} />
            <Stack.Screen name="PrimaryAuthentication" component={PrimaryAuthentication} options={{
              ...headerOptions,
              title: t('primaryAuthentication'), //初级认证
            }} />
            <Stack.Screen name="AdvancedAuthentication" component={AdvancedAuthentication} options={{
              ...headerOptions,
              title: t('advancedAuthentication'), //高级认证
            }} />
            <Stack.Screen name="RechargeChannel" component={RechargeChannel} options={{
              ...headerOptions,
              title: t('rechargeChannel'), //充值通道
            }} />
            <Stack.Screen name="Deposit" component={Deposit} options={{
              ...headerOptions,
              title: '', //银行卡充值
            }} />
            <Stack.Screen name="RechargeWayUnion" component={RechargeWayUnion} options={{
              ...headerOptions,
              title: t('Bank Transfer'), //充值通道rechargeWayUnion
            }} />
            <Stack.Screen name="ThirdpartyPay" component={ThirdpartyPay} options={{
              ...headerOptions,
              title: t('IDRBank'), //充值通道thirdpartyPay
            }} />
            <Stack.Screen name="Customer" component={Customer} options={{
              ...headerOptions,
              title: t('customer'), //客服
            }} />
            <Stack.Screen name="BetRecordDetail" component={BetRecordDetail} options={{
              ...headerOptions,
              title: '', //投注记录详情
            }} />
            <Stack.Screen name="Payment" component={Payment} options={{
              ...headerOptions,
              title: '', //支付页面
            }} />
            <Stack.Screen name="MiningRecord" component={MiningRecord} options={{
              ...headerOptions,
              title: t('investmentRecords'), //挖矿投资记录
            }} />
            <Stack.Screen name="About" component={About} options={{
              ...headerOptions,
              title: t('aboutUs'), //关于我们
            }} />
            <Stack.Screen name="LuckyWheel" component={LuckyWheel} options={{
              ...headerOptions,
              title: t('luckyWheel'), //幸运转盘
            }} />
            <Stack.Screen name="Loan" component={Loan} options={{
              ...headerOptions,
              title: t('loan'), //借贷
            }} />
            <Stack.Screen name="Repayment" component={Repayment} options={{
              ...headerOptions,
              title: t('repayment'), //借贷还款
            }} />
            <Stack.Screen name="QuickExchange" component={QuickExchange} options={{
              ...headerOptions,
              title: t('quickExchange'), //快捷兑换
            }} />
            <Stack.Screen name="QuickExchangeRecord" component={QuickExchangeRecord} options={{
              ...headerOptions,
              title: t('record'), //快捷兑换记录
            }} />
            <Stack.Screen name="Defi" component={Defi} options={{
              ...headerOptions,
              title: t('defi'), //defi质押
            }} />
            <Stack.Screen name="Mining" component={Mining} options={{
              ...headerOptions,
              title: t('mining'), //mining挖矿
            }} />
            <Stack.Screen name="EntrustOrder" component={EntrustOrder} options={{
              ...headerOptions,
              title: t('entrustOrder'), //委托单
            }} />
            <Stack.Screen name="ContractOrder" component={ContractOrder} options={{
              ...headerOptions,
              title: t('contract'), //合约单
            }} />


          </Stack.Navigator>
        </NavigationContainer>
      </SafeAreaProvider>
    </>
  );

}

const styles = StyleSheet.create({
  root: {
    maxWidth: 500
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000'
  },
  tabBarIcon: {
    width: 30,
    height: 30,
  },
  tabBarIcon2: {
    width: 20,
    height: 20,
  },
  tabBarIcon3: {
    width: 40,
    height: 40,
  },
  tabBarIcon4: {
    width: 23,
    height: 23,
  },
  headerStyle: {
    backgroundColor: commonStyle.bgColor,
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 0,
    // height:35
  },
  headerTitleStyle: {

  },
  whiteColor: {
    color: '#fff'
  },
  image: {
    alignItems: 'center',
    justifyContent: 'center',
    // width: Dimensions.get('window').width,
    // height: Dimensions.get('window').height,
  },
  indicatorContainer: {
    flexDirection: 'row',
    marginTop: 20,
  },
  indicator: {
    width: 7,
    height: 5,
    borderRadius: 2,
    margin: 5,
    backgroundColor: '#fff',
  },
  activeIndicator: {
    backgroundColor: '#13B777',
  },
  homeButton: {
    backgroundColor: '#13B777',
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 40,
    marginTop: 50,
  },
  homeButtonText: {
    color: 'white',
  },
});
