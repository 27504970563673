import React, { useState, useEffect, useRef, useCallback } from 'react';
import { View, Text, Image, TouchableOpacity, FlatList, ActivityIndicator, StyleSheet, RefreshControl, TextInput, Clipboard, Platform } from 'react-native';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style'
import { useTranslation } from 'react-i18next';
import { FontAwesome, MaterialIcons } from '@expo/vector-icons';
import { getTraderecord, exchangeget } from '../api/apis';
import { transactionType, formatTimestampToDateTime, vipGrade, unitconversion } from '../components/util'
import Toast from 'react-native-easy-toast'

const App = (props) => {

    const currency = props?.route?.params?.currency;

    const { t } = useTranslation();
    const toastRef = useRef(null);
    const [data, setData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [page, setPage] = useState(1);
    const [emptyShow, setEmptyShow] = useState(false);
    const [operatorType, setOperatorType] = useState(4); // 4：全部 1：买入 2：卖出
    const [recordCalled, setRecordCalled] = useState(false);
    const [disabledButton, setDisabledButton] = useState([]);

    useEffect(() => {
        handleRefresh();
    }, []);

    useEffect(() => {
        record();
    }, [page, operatorType, refreshing, loadingMore]);

    const handleRefresh = useCallback(() => {
        setRefreshing(true);
        setPage(1);
        setData([]);
        if (Platform.OS == 'web') {
            setLoadingMore(true);
        }
    }, []);

    const handleLoadMore = useCallback(() => {
        setLoadingMore(true);
        setPage(prevPage => parseInt(prevPage) + 1);
    }, []);


    //获取记录
    const record = async () => {

        const lotteryCode = (currency + 'usdt').toLowerCase()

        await exchangeget(lotteryCode, operatorType, false, page).then(response => {
            setRefreshing(false);
            setLoadingMore(false);

            if (response?.status == 1) {
                if (Array.isArray(response?.data)) {
                    const updateData = response.data.map(data => {
                        return { ...data, };
                    });
                    const dataList = data.concat(updateData)
                    if (page > 1) {
                        setData(dataList)
                    } else {
                        setData(updateData)
                        if (dataList.length == 0) {
                            setEmptyShow(true)
                        }
                    }
                } else {
                    setData([])
                    setEmptyShow(true)
                }
            }

        }).catch(error => {
            setRefreshing(false);
            setLoadingMore(false);
            // console.error(error);
        });
    };

    const cancelOrder = async (id) => {
        if (disabledButton[id]) {
            return; 
        }
        setDisabledButton(prevLoading => {
            const updatedLoading = [...prevLoading];
            updatedLoading[id] = true;
            return updatedLoading;
        });

        await exchangeget('', 3, id).then(response => {
            if (response?.status == 1) {
                toastRef.current.show(t('success'));
                handleRefresh()
            } else {
                toastRef.current.show(t('fail'));
            }

        }).finally(() => {
            setLoading(prevLoading => {
                const updatedLoading = { ...prevLoading };
                updatedLoading[id] = false;
                return updatedLoading;
            });
        });
    }


    const renderItem = ({ item }) => (
        <View style={styles.itemContainer}>
            <View style={styles.itemRowContainer}>
                <View style={{ flexDirection: 'row' }}>
                   
                    <Text style={styles.itemTextGreen}>{item.order_number}</Text>
                </View>
                <View>
                    {item.buy_status == 0 &&
                        <Text style={styles.itemTextYellow}>{t('inProgress')}</Text>
                    }
                    {item.buy_status == 1 &&
                        <Text style={styles.itemTextGreen}>{t('tradeSuccessful')}</Text>
                    }
                    {item.buy_status == 2 &&
                        <Text style={styles.itemTextRed}>{t('canceled')}</Text>
                    }
                </View>
            </View>
            <View style={styles.itemRowContainer}>

                <View>
                    <TouchableOpacity>
                        <Text style={styles.itemTextGreen}>
                            {formatTimestampToDateTime(item.buy_time / 1000)}
                        </Text>
                    </TouchableOpacity>
                </View>
                <View>
                    {item.buy_sale == 0 &&
                        <Text style={[styles.itemTextGreen, { marginRight: 5 }]}>{t('buy')}</Text>
                    }
                    {item.buy_sale == 1 &&
                        <Text style={[styles.itemTextRed, { marginRight: 5 }]}>{t('sell')}</Text>
                    }
           
                </View>
            </View>
            <View style={styles.itemRowContainer}>
                <View>
                    <Text style={styles.itemTextGreen}>{t('quantity')}: {item.buy_number}</Text>

                </View>
                <View>
                    {Number(item.buy_price) > 0 && <Text style={styles.itemTextRed}>{item.buy_price}</Text>}

                </View>
            </View>

            <View style={styles.itemRowContainer}>
                <View>
                    {Number(item.ord_price) > 0 &&
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={styles.itemTextGreen}>{t('ordPrice')}: </Text>
                            <Text style={styles.itemTextRed}>{item.ord_price}</Text>
                        </View>
                    }
                </View>
                <View>
                    {item.buy_status == 0 &&
                        <TouchableOpacity  disabled={disabledButton[item.cd_id]} onPress={() => { cancelOrder(item.cd_id) }}>
                            <Text style={{ textAlign: 'center', color: '#fff', backgroundColor: commonStyle.red, paddingHorizontal: 8, paddingVertical: 3, borderRadius: 3 }}>
                                {t('cancel')}
                            </Text>
                        </TouchableOpacity>
                    }
                </View>

            </View>

        </View>



    );

    return (
        <View style={styles.container}>

            {/* <Image style={styles.image} source={require('../../assets/imgs/common/empty.png')} /> */}

            <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginBottom: 10 }}>
                <TouchableOpacity style={[operatorType == 4 && styles.tabActive, { borderBottomColor: commonStyle.green, padding: 5, flex: 1 }]} onPress={() => { setOperatorType(4); handleRefresh() }} >
                    <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }}>{t('all')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[operatorType == 1 && styles.tabActive, { borderBottomColor: commonStyle.green, padding: 5, flex: 1 }]} onPress={() => { setOperatorType(1); handleRefresh() }} >
                    <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }}>{t('buy')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[operatorType == 2 && styles.tabActive, { borderBottomColor: commonStyle.green, padding: 5, flex: 1 }]} onPress={() => { setOperatorType(2); handleRefresh() }} >
                    <Text style={{ color: '#fff', fontSize: 16, textAlign: 'center' }}>{t('sell')}</Text>
                </TouchableOpacity>
            </View>

            <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={(item, index) => index}
                refreshControl={<RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />}
                onEndReached={!loadingMore && handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={loadingMore && <ActivityIndicator color={commonStyle.green} />}
            />

            {emptyShow && <View style={{ justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <Text style={{ color: '#fff' }}>{t('noData')}</Text>
            </View>}

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor
    },
    tabContainer: {
        flexDirection: 'row',
        paddingVertical: 10,
        backgroundColor: '#1B1D29',
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 10,
    },
    tabText: {
        color: commonStyle.textGreenColor,
    },
    itemTextGreen: {
        color: commonStyle.textGreenColor,
    },
    itemTextYellow: {
        color: commonStyle.yellow,
    },
    itemTextRed: {
        color: commonStyle.textRedColor,
    },
    itemTextGray: {
        color: commonStyle.textGrayColor
    },
    itemContainer: {
        flexDirection: "column",
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderBottomWidth: 1,
        backgroundColor: '#1B1D29',
        marginTop: 5,
    },
    itemRowContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 4
    },
    activeTabItem: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    input: {
        borderWidth: 0,
        borderBottomColor: 'transparent',
        color: '#fff',
        borderRadius: 2,
        padding: 5,
        width: '90%',
    },
    tabActive: {
        borderBottomWidth: 2
    }
});

export default App;