import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Image, Platform, Alert } from 'react-native';
import { useTranslation } from 'react-i18next';
import SpliteLine from '../components/spliteLine'
import { commonStyle } from '../components/style'
import { showAlert } from '../components/showAlert'
import { register,login, userCenter,getImageCode, generateToken, getWebsiteInfo } from '../api/apis';
import Toast, { DURATION } from 'react-native-easy-toast'
import { useNavigation ,useFocusEffect} from '@react-navigation/native';
import AsyncStorage from '@react-native-community/async-storage';

const RegisterScreen = () => {
    const { t } = useTranslation();
    const toastRef = useRef(null);
    const [userName, setUserName] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const [inviteCodeShow, setInviteCodeShow] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [imageCode, setImageCode] = useState('');
    const [imageCodeKey, setImageCodeKey] = useState('');
    const [imageData, setImageData] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [curr, setCurr] = useState('USD');

    const navigation = useNavigation();

    useEffect(() => {


        if (Platform.OS == 'web') {
            //分享过来的的链接
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (params.screen && params.screen == 'register' && params.invitation_code) {
                setInviteCode(params.invitation_code)
            }
        }

        handleGetimagecode()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            websiteInfo()
        }, [])
    );

    const websiteInfo = async () => {
        const res = await getWebsiteInfo();
        if (res.status == '1') {
            if (res.Data.curr != '') {
                setCurr(res.Data.curr)
            }
            if(inviteCode == '' &&  res.Data.invitecode != ''){
                setInviteCode(res.Data.invitecode)
                setInviteCodeShow(false)
            }else{
                setInviteCodeShow(true)
            }
           
        }
    }

    //获取验证码
    const handleGetimagecode = () => {
        const token = generateToken();
        getImageCode(token, 'reg').then(response => {
            if (response.Data.Code === '1') {
                const data = response.Data.data;
                const imageData = `data:image/jpg/png/gif;base64,${data}`;
                const imageCodeKey = token;
                setImageData(imageData);
                setImageCodeKey(imageCodeKey);
                // console.log(`验证码 key 值为：${imageCodeKey}`);
            } else {
                // console.log('不显示验证码');
            }
        }).catch(error => {
            // console.error(error);
        });
    };

    const handleUsernameChanged = text => {
        setUserName(text);
    };

    const handleInviteCodeChanged = text => {
        setInviteCode(text);
    };

    const handlePasswordChanged = text => {
        setPassword(text);
    };

    const handleConfirmPasswordChanged = text => {
        setConfirmPassword(text);
    };

    const handleImageCode = text => {
        setImageCode(text);
    };

    const handleRegister = async () => {
        
        // handleLogin()
        // return 

        if (isSubmitting) {
            return
        }
        if (userName === '') {
            toastRef.current.show(t('pleaseEnterYourPhoneOrEmail'));
            return;
        }
        if (inviteCode === '') {
            toastRef.current.show(t('pleaseEnterInviteCode'));
            return;
        }
        if (password === '') {
            toastRef.current.show(t('pleaseEnterPassword'));
            return;
        }

        var regex = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,16}$/;
        if (!regex.test(password)) {
            toastRef.current.show(t('pleaseEnter6-16charactersOfLettersAndNumbers'));
            return;
        }

        if (confirmPassword === '') {
            toastRef.current.show(t('pleaseEnterconfirmPassword'));
            return;
        }
        if (password !== confirmPassword) {
            toastRef.current.show(t('confirmPasswordInconsistency'));
            return;
        }

        if (imageData != '' && imageCode == '') {
            toastRef.current.show(t('pleaseEnterImageCode'));
            return;
        }

    
        try {
            setIsSubmitting(true)

            const response = await register(userName, password, inviteCode, imageCode, imageCodeKey, curr);
            // console.log(response);
            setIsSubmitting(false)
            handleGetimagecode()
            if (response.status == '1') {

                if (Platform.OS == 'web') {
                    const currentURL = new URL(window.location.href);
                    const baseUrl = currentURL.protocol + '//' + currentURL.hostname + (currentURL.port ? ':' + currentURL.port : '');
                    history.replaceState({}, document.title, baseUrl);
                }

                setImageCode('')

                // toastRef.current.show(t('success'));
                // navigation.navigate('Login')
                handleLogin()
                return;

               

                // if (Platform.OS == 'web') {
                //     const result = window.confirm(t('register') + t('success') + t('isLogin') + '?');
                //     if (result) {
                //         console.log('确认被点击');

                //         handleLogin()

                //         // navigation.navigate('Login')
                //     } else {
                //         console.log('取消被点击');
                //     }
                // } else {
                //     Alert.alert(
                //         t('register') + t('success'),
                //         t('register') + t('success') + t('isLogin') + '?',
                //         [
                //             { text: t('cancel'), style: 'cancel' },
                //             { text: t('login'), onPress: () => navigation.navigate('Login') }
                //         ]
                //     );
                // }
                return;
            }
            toastRef.current.show(response.Data.msg);
        } catch (error) {
            // console.error(`请求发生错误：${error}`);
            setIsSubmitting(false)
            handleGetimagecode()
            return
        }

    };

    // 登录
    const handleLogin = async () => {

        try {
            const response = await login(userName, password, '', '');
            if (response.status == '2') {
                navigation.navigate('Login',{u:userName,p:password})
            }
            if (response.status == '1') {
                toastRef.current.show(t('success'));

                //获取用户信息
                const ucenter = await userCenter();
                if (ucenter.status == '1') {
                    await AsyncStorage.setItem('userinfo', JSON.stringify(ucenter.Data1.item[0]));
                    navigation.navigate('MainNav', { screen: 'Home' });
                } else {
                    toastRef.current.show(ucenter.Data1.msg);
                }

            }
        } catch (error) {
            console.log(`登录请求发生错误：${error}`);
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.form}>
                <View>
                    {/* <Text style={styles.label}>{t('username')}</Text> */}
                    <TextInput
                        style={styles.input}
                        placeholder={t('phoneEmail')}
                        onChangeText={handleUsernameChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor={commonStyle.green}
                    />
                    {/* <SpliteLine /> */}
                </View>
                {inviteCodeShow && <View>
                    {/* <Text style={styles.label}>{t('inviteCode')}</Text> */}
                    <View style={styles.inputContainer}>
                        <TextInput
                            style={[styles.input, styles.inviteCodeInput]}
                            placeholder={t('inviteCode') + '(' + t('required') + ')'}
                            value={inviteCode}
                            onChangeText={handleInviteCodeChanged}
                            underlineColorAndroid="transparent"
                            placeholderTextColor={commonStyle.green}
                        />
                        {/* <Text style={styles.requiredText}>{t('required')}</Text> */}
                    </View>
                    {/* <SpliteLine /> */}
                </View> }
                <View>
                    {/* <Text style={styles.label}>{t('setPassword')}</Text> */}
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('setPassword')}
                        onChangeText={handlePasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor={commonStyle.green}
                    />
                    {/* <SpliteLine /> */}
                </View>
                <View>
                    {/* <Text style={styles.label}>{t('confirmPassword')}</Text> */}
                    <TextInput
                        style={styles.input}
                        secureTextEntry={true}
                        placeholder={t('confirmPassword')}
                        onChangeText={handleConfirmPasswordChanged}
                        underlineColorAndroid="transparent"
                        placeholderTextColor={commonStyle.green}
                    />
                    {/* <SpliteLine /> */}
                </View>

                {imageData && <View>
                    <View>
                        <TextInput
                            style={[styles.input]}
                            value={imageCode}
                            placeholder={t('imageCode')}
                            onChangeText={handleImageCode}
                            underlineColorAndroid="transparent"
                            maxLength={5}
                            placeholderTextColor={commonStyle.green}
                        />
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Image source={{ uri: imageData }} style={{ position: 'absolute', top: -52, right: 2, width: 100, height: 35, borderTopRightRadius: 3, borderBottomRightRadius: 3 }} />
                        </View>
                    </View>
                </View>
                }

                <TouchableOpacity style={styles.button} onPress={handleRegister}>
                    <Text style={styles.buttonText}>{t('registerLogin')}</Text>
                </TouchableOpacity>
            </View>
            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                // fadeInDuration={750}
                // fadeOutDuration={1000}
                // opacity={0.8}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        // justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '90%',
        marginTop: 30,
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        // placeholderTextColor:'#707070',
        // color: '#707070',
        marginBottom: 15,
        marginTop: 0,
        height: 40,
        borderWidth: 1,
        borderColor: commonStyle.green,
        borderRadius: 5,
        padding: 5,
        color: commonStyle.green,
        backgroundColor: '#222430'
        // borderBottomColor: 'transparent'
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        // backgroundColor: '#2C3445',
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 20,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        fontSize: 16
    },
});

export default RegisterScreen;