import React, { useRef, useEffect, useState } from 'react';
import { StyleSheet, View, Text, Image, ScrollView, TextInput, TouchableOpacity, Platform, Clipboard, FlatList, ActivityIndicator, Alert } from 'react-native';
import { commonStyle } from '../components/style';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import SpliteLine from '../components/spliteLine'
import { AntDesign } from '@expo/vector-icons';
import QRCodeSvg from 'react-native-qrcode-svg';
import { useTranslation } from 'react-i18next'
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';
import { getPromotionTest, getWebsiteInfo, getAgentreceive } from '../api/apis';
import AsyncStorage from '@react-native-community/async-storage';
import { isLoggedIn, vipGrade } from '../components/util';
import { commonConfig } from '../components/config';
// import HTML from 'react-native-render-html';
import { useWindowDimensions } from 'react-native';
import { WebView } from 'react-native-webview';
import Toast, { DURATION } from 'react-native-easy-toast'
import QRCode from 'qrcode-generator';
import { Svg, Rect } from 'react-native-svg'; // 导入需要的 SVG 组件
import { amountUnit } from '../components/util'
// import Clipboard from '@react-native-clipboard/clipboard'
import DraggableButton from '../components/draggableButton';
import { showAlert } from '../components/showAlert';

// http://127.0.0.1:8081/manageInvite 隐藏的生成下级代理页
const Publicize = () => {
    // const { width } = useWindowDimensions();
    const { t, i18n } = useTranslation();
    const navigation = useNavigation();
    const toastRef = useRef(null);
    const [qrCodeValue, setQrCodeValue] = useState('');
    const [dataMsg, setDataMsg] = useState('');
    const [isEnd, setIsEnd] = useState(false);
    const [userInfo, setUserInfo] = useState([]);
    const [vip, setVip] = useState('');  // vip等级
    const [avatar, setAvatar] = useState(''); // 头像
    const [showNo, setShowNo] = useState(false); // 是否可以领取
    const [teamPeople, setTeamPeople] = useState(0); // 团队总数
    const [team1People, setTeam1People] = useState(0); // 直属下级增加
    const [canGetPrize, setCanGetPrize] = useState(0); // 可领佣金
    const [content, setContent] = useState(''); // 教程内容
    // const [unit, setUnit] = useState(''); // 金额单位
    const [tab, setTab] = useState('1');
    const [receive, setReceive] = useState(t('receive'));
    const [proxySalary, setProxySalary] = useState([]); //代理薪资
    const [stateList, setStateList] = useState([t('all'), t('received'), t('receive'), t('expired')]);
    const [domain, setDomain] = useState('');
    const [staticDomain, setStaticDomain] = useState('');
    const [grade, setGrade] = useState(false);
    const [createUrl, setCreateUrl] = useState(false);
    const [sumbet, setSumbet] = useState('');
    const [sumrec, setSumrec] = useState('');
    const [gent, setGent] = useState('');
    const [gentShow, setGentShow] = useState(false);
    const [gentDefaultData, setGentDefaultData] = useState(false);
    const [gentData, setGentData] = useState([]);


    // const webViewRef = useRef(null);
    // const qrCodeRef = useRef(null);

    // web模式二维码
    const [qrCode, setQrCode] = useState('');


    useEffect(() => {
        init()
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            isLoggedIn(navigation)
            init()
        }, [])
    );

    const arrSlice = (arr, num) => {
        let index = 0;
        let newArray = [];
        while (index < arr.length) {
            newArray.push(arr.slice(index, index += num));
        }
        return newArray;
    }

    const greadLink = () => {
        getPromotionTest({ data: `{"Invite":"1"}` })
            .then(response => {
                // console.log(response);
                if (response.status == '1') {
                    setCreateUrl(false);
                    init();
                }
            })
            .catch(error => {
                console.error(error);
            });
    };

    const init = async () => {

        console.log('推广页')
        const userinfo = await AsyncStorage.getItem('userinfo');
        if (userinfo) {
            setUserInfo(JSON.parse(userinfo))
        }

        const websiteInfo = await getWebsiteInfo();
        // console.log('websiteInfoStorage')
        // console.log(websiteInfo)

        let websiteInfoDomain = ''
        let websiteInfoStaticDomain = ''
        if (websiteInfo.status == '1') {
            websiteInfoDomain = websiteInfo.Data.default_domain
            websiteInfoStaticDomain = websiteInfo.Data.static
            setDomain(websiteInfoDomain)
            setStaticDomain(websiteInfoStaticDomain)
        }


        //代理薪水
        getPromotionTest({ data: `{"commissionlist":{"time":2,"state":0}}` }).then(response => {
            // console.log('代理薪资')

            // response.status = '1'

            if (response.status == '1') {
                var proxydate = response.Data.proxydate;

                // proxydate = [
                //     {reward:1000,People:2,details:1}
                // ]

                // console.log(proxydate);

                var today = new Date();
                var options = { timeZone: 'Asia/Singapore' };
                var singaporeTime = today.toLocaleString('en-US', options);
                var date = new Date(singaporeTime);

                var year = date.getFullYear();
                var month = (date.getMonth() + 1).toString().padStart(2, '0');
                var day = date.getDate().toString().padStart(2, '0');
                var reward = 0;

                var data_list = [];
                for (var key in proxydate) {
                    if (year + '-' + month + '-' + day == key && proxydate[key].details == "1") {
                        proxydate[key].details = +proxydate[key].details + 1
                    }
                    reward = +reward + proxydate[key].reward;
                    data_list.push({
                        time: key,
                        num: proxydate[key].People,
                        sum: +proxydate[key].bet / 100,
                        comm: +proxydate[key].reward / 100,
                        source: t("bonusDistribution"),
                        state: +proxydate[key].details + 1,
                    });
                }
                reward = reward / 100;
                // console.log(data_list)
                setProxySalary(data_list)

            }
        }).catch(error => {
            // console.error(error);
        })


        getPromotionTest().then(response => {
            // console.log(response)
            // response.status = 0
            // response.Data.Code = -11
            // response.Data.msg = '活动已结束'

            if (response.status == '0') {
                if (response.Data.Code == "-11") {
                    // toastRef.current.show(response.Data.msg);
                    setIsEnd(true)
                    setDataMsg(response.Data.msg) // 未开启活动
                }
            }
            if (response.status == '1') {
                let txtContent = response.Data.sysproxy.txtContent
                txtContent = txtContent.replace('[server_address_image]', websiteInfoDomain)
                setContent(txtContent)
                
                setVip(vipGrade(response.Data1.item[0].user_integral))

                // console.log('头像', websiteInfoStaticDomain + '/Avatar/' + response.Data1.item[0].user_system_avatar + '.jpg')

                setAvatar(websiteInfoStaticDomain + '/Avatar/' + response.Data1.item[0].user_system_avatar + '.jpg')


                if (websiteInfo.status == '1') {
                    const curr = websiteInfo.Data.curr
                    const obj = amountUnit(curr, i18n.language)
                    const sysproxy = response.Data.sysproxy

                    // console.log(sysproxy)

                    const sumbet = (sysproxy.reward_gift_7 / 100).toFixed(2) + obj.icon
                    setSumbet(sumbet)
                    const sumrec = (sysproxy.reward_gift_8 / 100).toFixed(2) + obj.icon
                    setSumrec(sumrec)
                    const sysproxy2 = (sysproxy.reward_gift_9 / 100).toFixed(2) + obj.icon


                    if((sysproxy.reward_gift_9 / 100).toFixed(2) != '0.00'){
                        setGentShow(true)
                    }
                 
                    setGent(sysproxy2)

                }

                if (response.Icode && response.Icode.invitation_code != null) {
                    setQrCodeValue("?screen=register&invitation_code=" + response.Icode.invitation_code)

                    // this.lnk_show = true;
                    // this.lnk_show_1 = false;
                    let res = response.Data.data;
                    if (typeof res[2] !== 'undefined') {
                        setTeam1People(response.Data.data[0][0])
                        for (let i = 0; i < res[2].length; i++) {
                            canGetPrize += Number(res[2][i]);
                        }
                    }
                    if (canGetPrize > 0) {
                        setCanGetPrize(Math.round(canGetPrize) / 100)
                        setShowNo(true)
                        if (response.Data.Whether_to_receive == "2") {
                            setShowNo(false)
                            setReceive(t('notClaimable'))
                            console.log('不可领取')
                        }
                        if (response.Data.Whether_to_receive == "3") {
                            setShowNo(false)
                            setReceive(t('received'))
                            console.log('已领取')
                        }
                    }

                    if (response.Data.team.People >= 0) {
                        setTeamPeople(response.Data.team.People)
                    }
                } else {
                    setCreateUrl(true)
                    console.log('显示生成链接')
                }





            }
        }).catch(error => {
            // console.error(error);
        });

        //推广奖金
        const params = {
            data: `{"Invite":"1"}`
        }
        getAgentreceive(params).then(response => {

            // console.log(response)

            if (response.status == 1) {
                // console.log(response.Data)

                setGrade(response.grade)

                var agentdate = response.Data;

                if(!agentdate || agentdate == ''){
                    agentdate = []
                }
                
                var grade = response.grade;
                var state = 0;
                let sort = 0
                const list = []
                var data_list = [];
                for (var key in agentdate) {
                    if ((+agentdate[key].receive) > 0) {
                        state = 3;
                        sort = 1
                    }
                    else {
                        if ((+agentdate[key].bet) >= (+grade.reward_gift_7) && (+agentdate[key].recharge) >= (+grade.reward_gift_8)) {
                            state = 2;
                        } else {
                            state = 1;
                            // sort = 2
                        }

                        if(Number(agentdate[key].bet) > 0){
                            sort = 2
                        }else{
                            sort = 3
                        }
                        
                    }
                    list.push({ username: key, bet: (+agentdate[key].bet / 100).toFixed(0), recharge: (+agentdate[key].recharge / 100).toFixed(0), state: state, sort });
                }
                list.sort((a, b) => a.sort - b.sort)

                if (list.length > 0) {
                    setGentDefaultData(true)
                }

                // 补充空字符，使数据总数为100个
                while (list.length < 100) {
                    list.push({ username: '', bet: '', recharge: '', state: 1, sort: 0 });
                }

                data_list = arrSlice(list, 4)
                setGentData(data_list)
            }

        }).catch(error => {
            console.error(error);
        });


    }


    //点击开宝箱
    const getClick = (obj) => {
        // console.log(obj)
        const username = obj.username
        if (obj.state == 2) {
            const params = {
                data: '{"getreceive":"' + username + '"}',
            }
            getAgentreceive(params).then(response => {
                if (response.status == 1) {
                    console.log(response)
                    // showAlert(response.msg);
                    showAlert(t('prize') + t('receive') + ': ' + gent)
                    // let balance= res.msg+":"+(+(this.lastname.Data.sysproxy.reward_gift_9)/100).toFixed(2)+this.unit+"\n"+this.$t("comm.151")+":"+(+(res.Data.user_balance)/100).toFixed(2);
                    init()
                }else{
                    showAlert(response.Data.msg);
                }
            }).catch(error => {
                console.error(error);
            });
        }
    }


    // 领取奖励
    const receiveAward = () => {
        let Can_get_prize = canGetPrize + "";
        Can_get_prize = Can_get_prize.replace(/,/g, ""); //去掉奖励中的逗号
        getPromotionTest({ data: `{"Available_bonus":"'${Can_get_prize}'"}` })
            .then(response => {
                // console.log(response)
                if (response.status == '1') {
                    let res = response.Data.data; //获取到数据
                    if (res.Whether_to_receive == "1") {
                        toastRef.current.show(response.data.msg);
                    }
                }
                if (response.status == '0') {
                    toastRef.current.show(response.Data.msg);
                }
            }).catch(error => {
                // console.error(error);
            });
    }

    const selectTab = async (value) => {
        console.log('tab', value)
        setTab(value)
    }

    // 复制
    const handleCopy = () => {
        if (domain != '' && qrCodeValue != '') {
            Clipboard.setString(domain + qrCodeValue);
            toastRef.current.show(t('success'));
        }
    }

    let myQRCode = useRef();
    const saveQRCodeToBase64 = () => {

        if (Platform.OS != 'web') {
            myQRCode.toDataURL((data) => {
                // console.log(data); // 输出二维码的base64字符串
                // 你可以在此方法内部将base64编码的数据保存为图片
                saveBase64ImageToAlbum(data)
            });

        }

        if (Platform.OS == 'web') {

            console.log('web 二维码')


            const typeNumber = 4; // QR Code 类型
            const errorCorrectionLevel = 'L'; // 错误修正级别

            // 使用 qrcode-generator 库生成 QR Code 数据
            const qrcode = QRCode(typeNumber, errorCorrectionLevel);

            console.log('web二维码')
            console.log(domain + qrCodeValue)

            qrcode.addData(domain + qrCodeValue);
            qrcode.make();

            // 获取生成的 QR Code 数据并设置为 State
            const href = (qrcode.createDataURL(4, 0));
            const link = document.createElement('a');
            link.href = href;
            link.download = 'qrcode.png'; // 设置下载文件名
            link.click();
        }

    };

    async function saveBase64ImageToAlbum(base64Image) {

        const { status } = await MediaLibrary.requestPermissionsAsync();

        if (status === 'granted') {
            const filename = FileSystem.cacheDirectory + "qrcode.png";
            await FileSystem.writeAsStringAsync(filename, base64Image, {
                encoding: FileSystem.EncodingType.Base64,
            });

            const asset = await MediaLibrary.createAssetAsync(filename);

            const album = await MediaLibrary.getAlbumAsync('My Album');

            // if (album === null) {
            //     await MediaLibrary.createAlbumAsync('My Album', asset, true);
            // } else {
            //     await MediaLibrary.addAssetsToAlbumAsync([asset], album, true);
            // }

            toastRef.current.show(t('success'));
            console.log('Image saved to album!');
        } else if (status === 'denied') {
            console.error('Permissions to access camera roll denied!');
            // 在此处可以显示一个提示，告知用户需要授予访问相册的权限
            Alert.alert(t('cancel'))
        } else {
            Alert.alert(t('cancel'))
            console.error('Permissions to access camera roll not granted!');
            // 在此处可以显示一个提示，告知用户需要授予访问相册的权限
        }
    }

    // async function saveQRToDiskAsync(qrCodeBase64) {
    //     const { status } = await MediaLibrary.requestPermissionsAsync(true);
    //     if (status !== 'granted') {
    //         console.log('需要存储权限才能保存二维码');
    //         return;
    //     }

    //     try {
    //         const fileUri = FileSystem.cacheDirectory + 'qrcode.png';

    //         // 写入 Base64 数据到文件
    //         await FileSystem.writeAsStringAsync(fileUri, qrCodeBase64, {
    //             encoding: FileSystem.EncodingType.Base64,
    //         });

    //         // 保存文件到相册
    //         const asset = await MediaLibrary.createAssetAsync(fileUri);
    //         await MediaLibrary.createAlbumAsync('exchance', asset, false);
    //         console.log('图片已保存到相册');
    //     } catch (error) {
    //         console.error('保存图片时出错：', error);
    //     }
    // }

    const customHTML = `
    <!DOCTYPE html>
    <html>
    <head>
        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
        <style>
        body {background-color: #121420;}
        p {
            color: #fff  !important
        }
        span {
            color: #fff !important
        }
        </style>
    </head>
    <body>
        <div id="content">
            ${content}
        </div>
    </body>
    </html>
    `;

    const gradeHTML = `
    <!DOCTYPE html>
    <html>
    <head>
        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
        <style>
        body {background-color: #121420;}
        p {
            color: #fff  !important;
            font-size:14px
        }
        span {
            color: #fff !important
        }
        </style>
    </head>
    <body>
        <div id="content">
        <p>${t('agentReport.42a')} ${sumrec}, ${t('agentReport.42b')} ${sumbet}, ${t('agentReport.42c')} ${gent}</p>
        <p>${t('agentReport.42d')}</p>
        </div>
    </body>
    </html>
    `;


    return (
        <View style={styles.container}>
            <DraggableButton />

            {isEnd ?
                <Text style={{ color: '#fff' }}>{dataMsg}</Text>
                :

                <View >

                    <View style={[styles.rowContainer, { paddingHorizontal: 10, paddingVertical: 10 }]}>
                        <View style={styles.imageContainer}>
                            {avatar && <Image style={[styles.image, { width: 70, height: 70, borderRadius: 90 }]} source={{ uri: avatar }} />}
                        </View>
                        <View style={styles.contentContainer}>
                            <Text style={styles.whiteText}>{userInfo.user_name ? userInfo.user_name : userInfo.user_account_number}</Text>
                            <Text style={[styles.whiteText, styles.marginTop15]}>{vip.inte}</Text>
                        </View>
                        <View style={styles.actionContainer}>
                            {showNo ?
                                <TouchableOpacity onPress={() => receiveAward()}>
                                    <Text style={styles.actionText}>{receive}</Text>
                                </TouchableOpacity>
                                :
                                <Text style={[styles.actionText, { backgroundColor: 'gray' }]}>{t('receive')}</Text>
                            }
                            <TouchableOpacity onPress={() => selectTab('3')}>
                                <Text style={[styles.actionText, { backgroundColor: '#465DF5' }]}>{t('record')}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={styles.commissionContainer}>
                            <Text style={styles.commissionText}>{t('commission')}</Text>
                            <Text style={[styles.commissionText, styles.marginTop15]}>{canGetPrize.toFixed(2)}</Text>
                        </View>
                    </View>


                    {/* <View style={[styles.rowContainer, { paddingHorizontal: 10, paddingVertical: 10 }]}>
                        <TouchableOpacity onPress={() => selectTab('1')}>
                            <View style={styles.iconTextContainer}>
                                <Image style={styles.image} source={require('../../assets/imgs/publicize/promotionTutorial.png')} />
                                <Text style={styles.whiteText}>{t('me')}</Text>
                            </View>
                        </TouchableOpacity>


                       {gentShow &&  <TouchableOpacity onPress={() => selectTab('4')}>
                            <View style={styles.iconTextContainer}>
                                <Image style={styles.image} source={require('../../assets/imgs/publicize/agentSalary.png')} />
                                <Text style={styles.whiteText}>{t('tranytype.28')}</Text>
                            </View>
                        </TouchableOpacity>
                        }

                        <TouchableOpacity onPress={() => selectTab('2')}>
                            <View style={styles.iconTextContainer}>
                                <Image style={styles.image} source={require('../../assets/imgs/publicize/myPromotion.png')} />
                                <Text style={styles.whiteText}>{t('promotionTutorial')}</Text>
                            </View>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => selectTab('3')}>
                            <View style={styles.iconTextContainer}>
                                <Image style={styles.image} source={require('../../assets/imgs/publicize/agentSalary.png')} />
                                <Text style={styles.whiteText}>{t('proxySalary')}</Text>
                            </View>
                        </TouchableOpacity>

                    </View> */}

                    

                    <View style={[styles.rowContainer, { paddingHorizontal: 10, paddingVertical: 10,marginTop:0 }]}>
                        <View style={[styles.iconText, styles.flexColumn]}>
                            <TouchableOpacity style={styles.icon} onPress={() => { selectTab('1') }}>
                                <Image style={styles.image} source={require('../../assets/imgs/publicize/promotionTutorial.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('me')}</Text>
                        </View>
                        {gentShow && <View style={[styles.iconText, styles.flexColumn]}>
                            <TouchableOpacity style={styles.icon} onPress={() => { selectTab('4') }}>
                                <Image style={styles.image} source={require('../../assets/imgs/publicize/agentSalary.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('tranytype.28')}</Text>
                        </View>}
                        <View style={[styles.iconText, styles.flexColumn]}>
                            <TouchableOpacity style={styles.icon} onPress={() => { selectTab('2') }}>
                                <Image style={styles.image} source={require('../../assets/imgs/publicize/myPromotion.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('promotionTutorial')}</Text>
                        </View>
                        <View style={[styles.iconText, styles.flexColumn]}>
                            <TouchableOpacity style={styles.icon} onPress={() => { selectTab('3') }}>
                                <Image style={styles.image} source={require('../../assets/imgs/publicize/agentSalary.png')} />
                            </TouchableOpacity>
                            <Text style={styles.labelTop}>{t('proxySalary')}</Text>
                        </View>
                    </View>




                </View>
            }


            {tab == 1 && !isEnd &&
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                // style={styles.scrollView}
                >
                    <View>

                        {!qrCodeValue && createUrl &&
                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <TouchableOpacity onPress={greadLink}>
                                    <Text style={[styles.copyText, { width: 300, marginHorizontal: 20, marginVertical: 20 }]}>{t('myLink')}</Text>
                                </TouchableOpacity>
                            </View>
                        }

                        {qrCodeValue && domain != '' && <View style={styles.columnContainer}>
                            <Text style={[styles.whiteText, { marginBottom: 5 }]}>{t('shareUrl')}</Text>
                            <SpliteLine />
                            <View style={styles.rowContainer}>
                                <View style={styles.linkContainer}>
                                    <Text style={styles.linkText}>{t('myLink')}</Text>
                                </View>
                                <View style={styles.inputContainer}>
                                    <TextInput
                                        style={styles.textInput}
                                        value={domain + qrCodeValue}
                                        editable={false}
                                    />
                                    <TouchableOpacity style={styles.copyIconContainer} onPress={handleCopy}>
                                        <AntDesign name="copy1" size={15} color="#828B9F" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                            <View style={styles.rowContainer}>
                                <View style={styles.qrCodeContainer}>
                                    <View style={{ padding: 5 }}>

                                        <QRCodeSvg
                                            value={domain + qrCodeValue}
                                            size={120}
                                            color="black"
                                            backgroundColor="white"
                                            getRef={(c) => (myQRCode = c)}
                                        />

                                        <Svg width={0} height={0}>
                                            {/* 在 SVG 中绘制二维码 */}
                                            {qrCode && <Rect width={200} height={200} fill={`url(${qrCode})`} />}
                                        </Svg>

                                    </View>
                                    <TouchableOpacity onPress={saveQRCodeToBase64}>
                                        <Text style={styles.clickSaveText}>{t('saveQrcode')}</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={styles.actionsContainer}>
                                    <TouchableOpacity onPress={handleCopy}>
                                        <Text style={styles.copyText}>{t('copyLink')}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={saveQRCodeToBase64}>
                                        <Text style={styles.saveText}>{t('saveQrcode')}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>}

                        <View style={styles.columnContainer}>
                            <Text style={[styles.whiteText, { marginBottom: 5 }]}>{t('commission')}</Text>
                            <SpliteLine />
                            <View style={styles.rowContainer}>
                                <Text style={styles.grayText}>{t('eligibleCommission')}</Text>
                                <Text style={styles.grayText}>{canGetPrize.toFixed(2)}</Text>
                            </View>
                        </View>
                        <View style={styles.columnContainer}>
                            <Text style={[styles.whiteText, { marginBottom: 5 }]}>{t('performance')}</Text>
                            <SpliteLine />
                            <View style={styles.rowContainer}>
                                <Text style={styles.grayText}>{t('totalNumberTeams')}</Text>
                                <Text style={styles.grayText}>{teamPeople}{t('person')}</Text>
                            </View>
                            <SpliteLine />
                            <View style={styles.rowContainer}>
                                <Text style={styles.grayText}>{t('increaseDirectSubordinates')}</Text>
                                <Text style={styles.grayText}>{team1People}{t('person')}</Text>
                            </View>
                        </View>
                    </View>

                </ScrollView>
            }


            {tab == 3 && !isEnd &&
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={false}
                // style={styles.scrollView}
                >
                    <View>

                        {proxySalary.length == 0 &&
                            <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: '50%' }}>
                                <Text style={{ color: '#fff' }}>{t('noData')}</Text>
                            </View>
                        }
                        {proxySalary.length > 0 &&
                            <View style={styles.columnContainer}>
                                {proxySalary.map((item, index) => (
                                    <View key={index} style={styles.rowContainer}>
                                        <View >
                                            <Text style={styles.grayText}>{item.time}</Text>
                                            <Text style={styles.grayText}>{`${item.num}/${item.sum}`}</Text>
                                        </View>
                                        <View>
                                            <Text style={styles.grayText}>{item.source}</Text>
                                            <Text style={styles.grayText}>{t('commission')}${item.comm}</Text>
                                        </View>
                                        <View>
                                            <Text style={[styles.grayText]}>
                                                {t('state')}
                                            </Text>
                                            <Text style={[styles.grayText]}>
                                                {stateList[item.state]}
                                            </Text>
                                        </View>
                                    </View>
                                ))}
                            </View>
                        }

                    </View>
                </ScrollView>
            }

            {tab == 2 && !isEnd &&
                <>
                    {Platform.OS == 'web' && <iframe srcDoc={customHTML} width="100%" height="100%" style={{ margin: 0, padding: 0, border: '0px' }} />}
                    {Platform.OS != 'web' && <WebView
                        style={{ flex: 1, backgroundColor: '#000' }}
                        source={{ html: customHTML }}
                        javaScriptEnabled={true} />}
                </>
            }

            {tab == 4 && !isEnd &&
                <>
                    <View style={{ flex: 1 }}>
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                            nestedScrollEnabled={true}
                            style={{ height: '25%', display: gentDefaultData ? 'block' : 'none' }}
                        >
                            {gentData.map((group, groupIndex) => (
                                <View key={`group-${groupIndex}`} style={{ padding: 10, marginBottom: 0, flexDirection: 'row', justifyContent: 'space-between' }}>
                                    {group.map((item, itemIndex) => (
                                        <TouchableOpacity key={`item-${groupIndex}-${itemIndex}`} onPress={() => getClick(item)}>
                                            <View style={{ marginBottom: 5, position: 'relative',alignItems:'center' }}>
                                                {Number(item.state) == 1 && <Image source={require('../../assets/imgs/publicize/bx3.png')} style={styles.img} />}
                                                {Number(item.state) == 2 && <Image source={require('../../assets/imgs/publicize/bx1.png')} style={styles.img} />}
                                                {Number(item.state) == 3 && <Image source={require('../../assets/imgs/publicize/bx2.png')} style={styles.img} />}
                                            </View>
                                            <View style={{ marginTop: Platform.OS == 'web' ? 5 : -10}}>
                                                    <Text style={{height:20,color: '#fff', textAlign: 'center', marginTop: Platform.OS == 'web' ? -33 : -16 }} numberOfLines={1} ellipsizeMode="clip">
                                                        {Number(item.state) == 3 ? item.bet : ''} 
                                                    </Text>
                                                    <Text style={{ fontSize: 12, color: 'gray', textAlign: 'center' }} numberOfLines={1} ellipsizeMode="clip">
                                                        {item.username}
                                                    </Text>
                                                </View>
                                        </TouchableOpacity>
                                    ))}
                                </View>
                            ))}

                        </ScrollView>

                        <View style={{ flex: 1,display:'none' }}>
                            {Platform.OS == 'web' ? (
                                <iframe srcDoc={gradeHTML} width="100%" height="100%" style={{ margin: 0, padding: 0, border: '0px' }} />
                            ) : (
                                <WebView
                                    style={{ flex: 1, backgroundColor: '#000' }}
                                    source={{ html: gradeHTML }}
                                    javaScriptEnabled={true}
                                    overScrollMode={'always'} // 添加overScrollMode属性
                                />
                            )}
                        </View>
                    </View>


                </>
            }

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={300}
                textStyle={{ color: '#fff' }}
            />




        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        // justifyContent: 'center',
        flexDirection: 'colum',
        paddingHorizontal: 5
    },
    scrollView: {
        // width: '98%',
        marginHorizontal: 10,
        marginTop: 10
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#222430',
        borderRadius: 5,
        // paddingVertical: 10,
        marginTop: 10,
        marginBottom: 10,
    },
    imageContainer: {
        justifyContent: 'center',
    },
    contentContainer: {
        justifyContent: 'center',
    },
    actionContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    commissionContainer: {
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    iconTextContainer: {
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'column',
    },
    columnContainer: {
        flexDirection: 'column',
        backgroundColor: '#222430',
        borderRadius: 5,
        marginBottom: 10,
        padding: 10,
    },
    linkContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    inputContainer: {
        flex: 3,
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 3,
        alignItems: 'center',
    },
    qrCodeContainer: {
        backgroundColor: '#FFF',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 5,
    },
    actionsContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 2,
    },
    image: {
        width: 30,
        height: 30,
    },
    whiteText: {
        color: '#fff',
    },
    marginTop15: {
        marginTop: 15,
    },
    actionText: {
        color: '#fff',
        backgroundColor: '#13B777',
        width: 70,
        padding: 5,
        borderRadius: 5,
        textAlign: 'center',
        marginTop: 5,
    },
    commissionText: {
        color: '#F5465C',
    },
    grayText: {
        color: '#828B9F',
    },
    linkText: {
        color: '#828B9F',
        padding: 2,
    },
    textInput: {
        flex: 1,
        color: '#828B9F',
        paddingLeft: 5,
    },
    copyIconContainer: {
        marginLeft: 2,
    },
    saveText: {
        color: '#fff',
        backgroundColor: '#465DF5',
        height: 30,
        lineHeight: 30,
        textAlign: 'center',
        borderRadius: 5,
        width: 150,
    },
    clickSaveText: {
        color: '#fff',
        backgroundColor: '#13B777',
        height: 25,
        lineHeight: 25,
        textAlign: 'center',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    copyText: {
        color: '#fff',
        backgroundColor: '#13B777',
        width: 150,
        padding: 6,
        borderRadius: 5,
        textAlign: 'center',
        margin: 10,
    },
    content: {
        // backgroundColor:'#fff',
        // fontSize: 16,
    },
    img: {
        height: 55,
        width: 55,
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        color: '#828B9F',
    },
    labelTop: {
        color: commonStyle.textWhiteColor,
        marginTop: 2,
        textAlign:'center'
    },
    flexColumn: {
        flexDirection: 'column',
        flex: 1
    }
});

export default Publicize;