import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';

import { View, ScrollView, Text, TextInput, StyleSheet, TouchableOpacity, TouchableWithoutFeedback ,Image,ActivityIndicator} from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style';
import SpliteLine from '../components/spliteLine';
import Toast from 'react-native-easy-toast'
import { getWebsiteInfo, getUserbalance, getrebate, addbetting, getSymbolPrice, getHuobiPrice, servertime,getMarketDepth,binanceDepth,binanceTickerPrice,coinPrice } from '../api/apis';
import AsyncStorage from '@react-native-community/async-storage';
import { timeConvers, unitconversion,virtual2,validateAmount } from '../components/util'
import { showAlert } from '../components/showAlert';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { Feather } from '@expo/vector-icons';

import Depth from './depth';


// 期权
const App = forwardRef((props, ref) => {
    const navigation = useNavigation();
    const { t } = useTranslation();
    const toastRef = useRef(null);

    // const [data, setData] = useState(props?.route?.params);
    const [currency, setCurrency] = useState(props?.route?.params?.currency ? props?.route?.params?.currency : 'BTC');
    const [upDownVal, setUpDownVal] = useState(props?.route?.params?.currency ? props?.route?.params?.upDown : 'up');

    const [amount, setAmount] = useState('');
    const [isValidAmount, setValidAmount] = useState(true);
    const [selectedAmount, setSelectedAmount] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState(0);
    const [bonus, setBonus] = useState(30);
    const [curr, setCurr] = useState('USD');
    const [userbalance, setUserbalance] = useState(0); // 余额
    const [currentPrice, setCurrentPrice] = useState(0); //现价
    const [profit, setProfit] = useState(0.00); // 盈利
    const [rebateData, setRebateData] = useState([]);
    const [rebate, setRebate] = useState(0);
    const [bettingIssuseNo, setbettingIssuseNo] = useState(''); // 期号
    const [odds, setOdds] = useState(''); // 赔率
    const [isSubmit, setIsSubmit] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false); //提交成功返回状态
    const [progress, setProgress] = useState(0); //进度条
    const [isRunning, setIsRunning] = useState(false); //是否开始倒计时
    const [seconds, setSeconds] = useState(60);
    const [minPrice, setMinPrice] = useState(60);

    // 124:10,125:20,126:30,127:30,128:50  最低金额
    const [periods, setPeriods] = useState([]);

    const [headerRightButton, setHeaderRightButton] = useState('none');

    useEffect(() => {
        init()
    }, []);

    React.useImperativeHandle(ref, () => ({
        goToExchangeScreen,
    }));


    const handleHeaderRightClick = () => {
        if (headerRightButton == 'none') {
            setHeaderRightButton('block')
        } else {
            setHeaderRightButton('none')
        }
    };

    // React.useLayoutEffect(() => {

    //     navigation.setOptions({
    //         headerLeft: () => (
    //             <TouchableOpacity style={{flexDirection:'row'}} onPress={() => handleHeaderRightClick()}>
    //                 <Feather name="list" size={24} color='#fff' />
    //                 <Text style={{color:'#fff',marginLeft:5,fontSize:18}}>{currency}</Text>
    //             </TouchableOpacity>
    //         ),
    //         headerRight: () => (
    //             <View>
    //                 <TouchableOpacity onPress={() => {navigation.navigate('Chart',{currency:currency})}}>
    //                     <Image style={{width:30,height:30}} source={require('../../assets/imgs/contract/tab.png')} />
    //                 </TouchableOpacity>
    //             </View>
    //         ),
    //     });
    // }, [navigation,headerRightButton]);



    const closePage = () => {
        setIsSuccess(false)
        setIsRunning(false);
        setSeconds(bonus); 
        setProgress(0)
        setAmount('')
    }

    useFocusEffect(
        React.useCallback(() => {
            let interval;
            if (isRunning && seconds > -1) {
                 interval = setInterval(() => {
                    // console.log(bonus, seconds, progress)
                    if(seconds > 0){
                        setSeconds((seconds) => seconds - 1);
                    }
                    
                    setProgress((progress) => progress + 100 / bonus)
                }, 1500);
            }
            if (progress >= 100) {

                setTimeout(() => {
                    setIsRunning(false);
                    clearInterval(interval);
                    // console.log('去订单页')
                    setIsSuccess(false)
                    setAmount('')
                    setProgress(0)
                    navigation.navigate('BetRecord')
                }, 1000);
    
            }
            return () => {
                // 在组件失去焦点时清除定时器
                
                clearInterval(interval);
            };
        }, [seconds, progress, isRunning,amount,isSuccess])
    );


    // useEffect(() => {
    //     let interval;

    //     if (isRunning && seconds > -1) {
    //         interval = setInterval(() => {
    //             // console.log(bonus, seconds, progress)
    //             setSeconds((seconds) => seconds - 1);
    //             setProgress((progress) => progress + 100 / bonus)
    //         }, 1000);
    //     }

    //     if (progress >= 100) {

    //         setTimeout(() => {
    //             setIsRunning(false);
    //             clearInterval(interval);
    //             // console.log('去订单页')
    //             setIsSuccess(false)
    //             setAmount('')
    //             navigation.navigate('BetRecord')
    //         }, 1000);

    //     }

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [isRunning, seconds, progress]);

    useFocusEffect(
        React.useCallback(() => {

            getUserbalance().then(response => {
                if (response.status == 1) {
                    setUserbalance(response.Data.BackData)
                }
            }).catch(error => {
                // console.error(error);
            });

        }, [])
    );

    const init = async () => {

        // console.log('参数2',props.currency)
        setCurrency(props.currency)


        // const symbolPrice = await getSymbolPrice(currency);
        // if (symbolPrice) {
        //     setCurrentPrice(symbolPrice)
        // } else {
        //     const huobiPrice = await getHuobiPrice(currency);
        //     if (huobiPrice) {
        //         setCurrentPrice(huobiPrice)
        //     }
        // }

   
        const newPeriods = [];

        const response = await getWebsiteInfo();
        if (response.status == '1') {
            await AsyncStorage.setItem('websiteInfo', JSON.stringify(response));
            if (response.Data.curr != "") {
                // setCurr(response.Data.curr)
            } else {
                setCurr("USD")
            }

            // console.log('彩种')
            // console.log(response.L.n)

            // setPeriods()

            //n_s  1正常  0的不显示  2禁售
            const ln = response.L.n
           
            ln.sort((a, b) => {
                return a.c.localeCompare(b.c);
            });

            ln.map((element) => {
                // if (element.c == 125) {
                //     element.n_s = 2
                // }
                if (element.n_s != 0) {
                    if (element.c == 124) {
                        newPeriods.push({ id: 124, label: '30', odds: '', minPrice: 10, num_state: element.n_s })
                    }
                    if (element.c == 125) {
                        newPeriods.push({ id: 125, label: '60', odds: '', minPrice: 20, num_state: element.n_s })
                    }
                    if (element.c == 126) {
                        newPeriods.push({ id: 126, label: '120', odds: '', minPrice: 30, num_state: element.n_s })
                    }
                    if (element.c == 127) {
                        newPeriods.push({ id: 127, label: '180', odds: '', minPrice: 30, num_state: element.n_s })
                    }
                    if (element.c == 128) {
                        newPeriods.push({ id: 128, label: '240', odds: '', minPrice: 50, num_state: element.n_s })
                    }
                    if (element.c == 129) {
                        newPeriods.push({ id: 129, label: '300', odds: '', minPrice: 0, num_state: element.n_s })
                    }
                }
            })
            // setPeriods(newPeriods)

            // [
            //     { id: 124, label: '30', odds: '',minPrice:10 ,disable:false},
            //     { id: 125, label: '60', odds: '' ,minPrice:20,disable:false},
            //     { id: 126, label: '120', odds: '' ,minPrice:30,disable:false},
            //     { id: 127, label: '180', odds: '' ,minPrice:30,disable:false},
            //     { id: 128, label: '240', odds: '' ,minPrice:50,disable:false},
            //     { id: 129, label: '300', odds: '' ,minPrice:0,disable:false}
            // ]

        }

        // console.log('newPeriods:')
        // console.log(newPeriods)


        //获取赔率
        getrebate('virtual').then(response => {
            if (response.status == 1) {
                // setRebate(response.Data.Rebate)
                // setRebateData(response.Data.item)

                const bonus = response.Data.item[0].Bonus
                if (typeof bonus === 'string') {
                    const updateData = newPeriods.map((item) => {
                        item.odds = bonus / 2
                        return item;
                    })
                    setPeriods(updateData)
                } else {
                    const updateData = newPeriods.map((item) => {
                        if (item.id == 124) {
                            item.odds = bonus[0] + '%'
                        }
                        if (item.id == 125) {
                            item.odds = bonus[1] + '%'
                        }
                        if (item.id == 126) {
                            item.odds = bonus[2] + '%'
                        }
                        if (item.id == 127) {
                            item.odds = bonus[3] + '%'
                        }
                        if (item.id == 128) {
                            item.odds = bonus[4] + '%'
                        }
                        if (item.id == 129) {
                            item.odds = bonus[5] + '%'
                        }
                        return item;
                    })
                    setPeriods(updateData)
                }
                // setOdds(response.Data.item[0].Bonus / 2)
            }
        }).catch(error => {
            console.error(error);
        });

        // servertime().then(response => {
        //     if (response.status == 1) {
        //         let tomorrow = response.Data.time + 24 * 60 * 60 * 1000;
        //         tomorrow = timeConvers(tomorrow);
        //         let IssuseNo = `${tomorrow.Y}${tomorrow.M.padStart(2, '0')}${tomorrow.D.padStart(2, '0')}`;
        //         // let IssuseNo = `${tomorrow.Y}${tomorrow.M}${tomorrow.D}`
        //         setbettingIssuseNo(IssuseNo)

        //     }
        // }).catch(error => {
        //     console.error(error);
        // });


    }

    const updateProfit = (amount, bonus) => {
        if (Number(amount) > 0 && Number(bonus) > 0) {
            const newProfit = (Number(amount) * Number(bonus) / 2);
            setProfit(newProfit);
        }
    };

    const setUpDown = (value) => {

        setUpDownVal(data => {
            let newUpDown;
            if (upDownVal === 'all') {
                newUpDown = value === 'up' ? 'down' : 'up';
            } else {
                if (upDownVal === value) {
                    return data;
                } else {
                    newUpDown = 'all';
                }
            }
            return newUpDown;
        });


        // setData(data => {
        //     let newUpDown;
        //     if (upDownVal === 'all') {
        //         newUpDown = value === 'up' ? 'down' : 'up';
        //     } else {
        //         if (upDownVal === value) {
        //             return data;
        //         } else {
        //             newUpDown = 'all';
        //         }
        //     }
        //     return {
        //         ...data,
        //         upDown: newUpDown
        //     };
        // });
    }

    const handleMoneyChanged = (text) => {
        if(!validateAmount(text)){
            return;
        }
        text = text.replace(/[^0-9]/g, '');
        setAmount(text);
        setValidAmount(true);
    };


    // 下注期数
    const calPer = (issue, sec, tomorrow, time, max_period) => {
        let issues = "";
        selectedPeriod == "7" ? (issues = "0001") : (issues = "001");
        let current_issue = "";
        if (issue == 0 && sec == 0) {
            current_issue = `${time.M}${time.D}${issues}`;
        } else if (issue == 1 && (sec < 60 || sec == 0)) {
            current_issue = `${time.M}${time.D}${issues}`;
        } else if (issue > max_period || issue == 0) {
            current_issue = `${tomorrow.M}${tomorrow.D}${issues}`;
        } else {
            sec % 60 == 0 ? (issue = +issue + 1) : (issue = issue);
            issue = chargeIssue(issue);
            current_issue = `${time.M}${time.D}${issue}`;
        }
        return current_issue
    }

    // 判断期数是否需要加0   
    //  let issue = +this.current_issue.slice(4) + 1;
    //  this.calPer(issue);
    const chargeIssue = (issue) => {
        if (+issue < 10) {
            issue = "00" + issue;
        } else if (+issue >= 10 && +issue < 100) {
            issue = "0" + issue;
        } else {
            issue = issue;
        }
        return issue;
    }


    //确认下单
    const handlePressConfirmOrder = () => {
        if (!isSubmit) {
            return;
        }

        updateProfit(amount, bonus)

        if (amount === '' || parseFloat(amount) <= 0) {
            setValidAmount(false);
            toastRef.current.show(t('invalidAmount'));
            return;
        }

        // 使用正则表达式匹配金额，要求小数点后最多两位
        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(amount)) {
            setValidAmount(false);
            toastRef.current.show(t('invalidAmount'));
            return;
        }

        if (amount < 1) {
            setValidAmount(false);
            toastRef.current.show(t('invalidAmount'));
            return;
        }
        setValidAmount(true)

        if (selectedPeriod == '' || selectedPeriod == 0) {
            toastRef.current.show(t('pleaseSelectPeriod'));
            return;
        }

        if (Number(userbalance) < Number(amount)) {
            toastRef.current.show(t('insufficientBalance'));
            return
        }

        // toastRef.current.show(t('confirmOrder'));

        setIsSubmit(false)

        servertime().then(response => {
            if (response.status == 1) {

                let res = response.Data.time;
                let time = timeConvers(res);

                let tomorrow = res + 24 * 60 * 60 * 1000;
                tomorrow = timeConvers(tomorrow);

                let yesterday = res - 24 * 60 * 60 * 1000;
                yesterday = timeConvers(yesterday);

                let sec = +time.h * 3600 + +time.min * 60 + +time.s; // 00:00到现在的秒数

                let few_min = 0.5 //几分钟一期
                let max_period = 2880 //最大期数

                if (selectedPeriod == 125) {
                    few_min = 1
                    max_period = 1440
                }
                if (selectedPeriod == 126) {
                    few_min = 2
                    max_period = 720
                }
                if (selectedPeriod == 127) {
                    few_min = 3
                    max_period = 480
                }
                if (selectedPeriod == 128) {
                    few_min = 4
                    max_period = 360
                }
                if (selectedPeriod == 129) {
                    few_min = 5
                    max_period = 288
                }

                let per_issue = 60 * + few_min; // 每期秒数
                let issue = Math.ceil(sec / per_issue);

                const betting_issuseNo = tomorrow.Y + calPer(issue, sec, tomorrow, time, max_period)


                // const betting_issuseNo = `${tomorrow.Y}${tomorrow.M}${tomorrow.D}${tomorrow.h}${tomorrow.min}`

                // const betting_issuseNo = `${tomorrow.Y}${tomorrow.M}${tomorrow.D}${tomorrow.h}${tomorrow.min}`.padStart(12, '0');

                // console.log(selectedPeriod, amount, currency, upDownVal, betting_issuseNo)

                // console.log(tomorrow)

                // const play_id = 1
                const sel_bet = 1

                const betting_money = upDownVal == 'all' ? amount * 2 : amount

                const formData = {
                    lottery_code: selectedPeriod, //彩种
                    betting_number: upDownVal, //投注号码 down,up
                    betting_money: betting_money, //投注金额
                    betting_count: sel_bet, //注数
                    play_detail_code: currency, //玩法
                    // betting_issuseNo: betting_issuseNo, //下注期号
                    betting_issuseNo: betting_issuseNo,
                };
                console.log(formData)

                addbetting(formData).then(response => {
                    setIsSubmit(true)
                    console.log(response)
                    if (response.status == 1) {
                        startCountdown()
                        // showAlert(response.msg)
                    } else {
                        showAlert(response.Data.msg)
                    }
                }).catch(error => {
                    setIsSubmit(true)
                    console.log(error)
                    showAlert(t('fail'));
                })
            }
        }).catch(error => {
            setIsSubmit(true)
            console.error(error);
        });


    };

    const goToExchangeScreen = (currencyVal) => {
        setHeaderRightButton('none')
        setCurrency(currencyVal)
        setAmount('')
        // setAsks([])
        // setBids([])
        init()
    }

    const PeriodItems = periods.map((period, index) => (
        <TouchableWithoutFeedback key={index} onPress={() => handlePeriodClick(period.id, period.label, period.num_state,period.minPrice)}>
            <View key={index} style={[styles.periodItem, selectedPeriod === period.id && styles.selectedPeriodItem]}>
                <Text style={styles.periodText}>{period.label}s</Text>
                <Text style={styles.periodText}>{period.odds}</Text>
            </View>
        </TouchableWithoutFeedback>
    ));

    //选中金额
    const amounts = [100, 500, 1000, 5000];
    const amountItems = amounts.map((amount, index) => (
        <TouchableWithoutFeedback key={index} onPress={() => handleAmountClick(amount)}>
            <View key={index} style={[styles.amountItem, selectedAmount === amount && styles.selectedAmountItem]}>
                <Text style={styles.amountText}>{amount}</Text>
                <Text style={styles.amountText}>{curr}</Text>
            </View>
        </TouchableWithoutFeedback>
    ));

    const handleAmountClick = (amount) => {
        // console.log('Clicked amount:', amount);
        // 在这里你可以执行其他逻辑操作
        setSelectedAmount(amount);
        setAmount(amount + '.00');
        setValidAmount(true); // Reset validation state
        updateProfit(amount, bonus)
    };

    const handlePeriodClick = (label, bonus,n_s, price) => {

        if(n_s == 2){
            showAlert(t('soldOut'))
            return;
        }
        
        // console.log('Clicked label', label);
        // console.log('Clicked bonus', bonus);
        setSelectedPeriod(label);
        setBonus(bonus)
        updateProfit(amount, bonus)
        setMinPrice(price)
    };

    const startCountdown = () => {
        // console.log('Clicked bonus:',bonus);
        setIsSuccess(true);
        // console.log('Clicked Success');
        setIsRunning(true);
        // console.log('Clicked Running');
        setSeconds(bonus); // 重置剩余秒数为初始值
        // console.log('Clicked Seconds');
        setProgress(0)
        // console.log('Clicked Progress');
    };

    const BottomButtons = () => {
        return (
            <View style={styles.bottomContainer}>
                <TouchableOpacity style={[styles.button]} onPress={() => setUpDown('up')} >
                    <View style={[styles.buttonLeft, (upDownVal == 'up' || upDownVal == 'all') && styles.selectedButton]}>
                        <Text style={[styles.bottomText]}>{t('buyUp')}</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button]} onPress={() => setUpDown('down')}>
                    <View style={[styles.buttonRight, (upDownVal == 'down' || upDownVal == 'all') && styles.selectedButton]}>
                        <Text style={styles.bottomText}>{t('buyDown')}</Text>
                    </View>

                </TouchableOpacity>
            </View>
        );
    };

    const TradeInfo = () => {
        return (<View style={styles.tradeInfoContainer}>
            <View style={styles.tradeInfoItem}>
                <Text style={styles.tradeInfoLabel}>{t('tradingPair')}</Text>
                <Text style={styles.tradeInfoValue}>{curr}</Text>
            </View>
            <View style={styles.tradeInfoItem}>
                <Text style={styles.tradeInfoLabel}>{t('direction')}</Text>
                {upDownVal === 'up' ? (
                    <Text style={styles.tradeInfoValueGreen}>{t('buyUp')}</Text>
                ) : upDownVal === 'down' ? (
                    <Text style={styles.tradeInfoValueRed}>{t('buyDown')}</Text>
                ) : (
                    <Text style={styles.tradeInfoValue}>{t('all')}</Text>
                )}
            </View>
            {/* <View style={styles.tradeInfoItem}>
                <Text style={styles.tradeInfoLabel}>{t('currentPrice')}</Text>
                <Text style={styles.tradeInfoValue}>{currentPrice}</Text>
            </View> */}
            <View style={styles.tradeInfoItem}>
                <Text style={styles.tradeInfoLabel}>{t('amount')}</Text>
                <Text style={styles.tradeInfoValue}>{amount == '' ? '0' : amount} {curr}</Text>
            </View>
            {/* <View style={styles.tradeInfoItem}>
                <Text style={styles.tradeInfoLabel}>{t('estimatedProfit')}</Text>
                {upDownVal === 'up' ? (
                    <Text style={styles.tradeInfoValueGreen}>{profit} {curr}</Text>
                ) : (
                    <Text style={styles.tradeInfoValueRed}>{profit} {curr}</Text>
                )}
            </View> */}
        </View>)
    }

    return (
        <View style={styles.container}>

            {/* <View style={{ display: headerRightButton, backgroundColor: 'rgba(18, 20, 32, 1)', borderRadius: 3, borderWidth: 1, borderColor: 'gray', width: 140, position: 'absolute', top: 0, left: 5, zIndex: 999 }}>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {Object.entries(virtual()).map(([symbol, name]) => (
                        <View key={name}>
                                <TouchableOpacity onPress={() => goToExchangeScreen(symbol)}>
                                <View style={{ width: 68 }}>
                                    <Text style={{ textAlign: 'center', color: '#fff', marginVertical: 10 }}>{symbol}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    ))}
                </View>
            </View> */}

            {isSuccess && <View style={styles.orderContainer}>

                <View style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 50 }}>

                    <AnimatedCircularProgress
                        size={160}
                        width={10}
                        fill={progress}
                        tintColor={commonStyle.green}
                        backgroundColor="#cccccc"
                        rotation={0}
                        lineCap="round"
                    >
                        {(fill) => <View style={{ justifyContent: 'center', alignItems: 'center', borderRadius: 999, backgroundColor: '#222430', height: 140, width: 140, }}>
                            <Text style={{ color: '#fff', fontSize: 30 }}>{seconds} s</Text>
                            <Text style={{ color: '#fff', fontSize: 16 }}>{t('amount')}</Text>
                            <Text style={{ color: '#f5465c' }}>{amount == '' ? amount : Number(amount).toFixed(2)}</Text>
                        </View>
                        }
                    </AnimatedCircularProgress>

                </View>
                <TradeInfo />
                <View style={styles.confirmButtonContainer}>
                    <TouchableOpacity style={styles.confirmButton} onPress={() =>{closePage(); navigation.navigate('MainNav', { screen: 'Home' })}}>
                        <Text style={styles.confirmButtonText}>{t('home')}</Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity style={styles.confirmButton} onPress={() => startCountdown()}>
                        <Text style={styles.confirmButtonText}>开始</Text>
                    </TouchableOpacity> */}
                </View>
            </View>}

            {!isSuccess && <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={styles.orderContainer}
            >
                <View>
                    <View style={styles.currencyContainer}>
                        <Text style={styles.currencyText}>{currency}/{curr}</Text>
                    </View>
                    <SpliteLine />

                    <BottomButtons />

                    <View>

                    </View>

                    {periods.length == 0 && <ActivityIndicator color={commonStyle.green} />}
                    {periods.length > 0 && <View>
                        <Text style={styles.sectionTitle}>{t('selectPeriod')}</Text>
                        <View style={{flexDirection:'row'}}>
                        <View style={[styles.periodContainer,{flex:1}]}>
                            {PeriodItems}
                        </View>
                        <Depth currency={currency} />
                        </View>
                    </View>}
                   
                    <View>
                        <Text style={styles.sectionTitle}>{t('amount')}</Text>
                        <View>
                            <TextInput
                                style={[
                                    styles.customAmountInput,
                                    !isValidAmount && { borderColor: 'red' }, // Highlight input border when invalid amount
                                ]}
                                placeholder='0.00'
                                onChangeText={handleMoneyChanged}
                                value={amount}
                                placeholderTextColor="#707070"
                            />
                        </View>
                    </View>
                    <View>
                        <Text style={styles.balanceText}>{t('accountBalance')}: {unitconversion(userbalance, curr)} {curr}</Text>
                    </View>
                    <SpliteLine />


                    <TradeInfo />

                    <View style={styles.confirmButtonContainer}>
                        <TouchableOpacity onPress={handlePressConfirmOrder} style={styles.confirmButton}>
                            <Text style={styles.confirmButtonText}>{t('confirmOrder')}</Text>
                        </TouchableOpacity>
                    </View>


                </View>
            </ScrollView>}


            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // alignItems: 'center',
        // justifyContent: 'center',
        backgroundColor: commonStyle.bgColor,
        width:'100%',
     
    },
    orderContainer: {
        padding:10
        // width: '98%',
    },
    currencyContainer: {
        // paddingVertical: 10,
    },
    currencyText: {
        fontWeight: 'bold',
        fontSize: 16,
        color: commonStyle.textWhiteColor,
    },
    sectionTitle: {
        color: commonStyle.textGreyColor,
        marginVertical: 8,
    },
    periodContainer: {
        flexDirection: 'row',
        justifyContent: "space-start",
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    periodItem: {
        width: 70,
        height: 60,
        backgroundColor: commonStyle.green,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
        marginBottom: 10,
        justifyContent: 'space-evenly',
    },
    periodText: {
        color: commonStyle.textWhiteColor,
        textAlign: 'center',
    },
    amountContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 10,
    },
    amountItem: {
        width: 70,
        height: 60,
        backgroundColor: commonStyle.green,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 5,
        marginBottom: 10,
        justifyContent: 'space-evenly',
    },
    amountText: {
        color: commonStyle.textWhiteColor,
        textAlign: 'center',
    },
    customAmountInput: {
        backgroundColor: '#212332',
        height: 40,
        color: '#13B777',
        borderRadius: 5,
        paddingLeft: 10,
        marginTop: 8,
        borderWidth: 1,
        borderColor: '#444E63',
    },
    balanceText: {
        color: commonStyle.textGreyColor,
        marginVertical: 8,
        marginTop: 20,
    },
    tradeInfoContainer: {
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    tradeInfoItem: {
        height: 50,
        justifyContent: 'space-between',
    },
    tradeInfoLabel: {
        color: commonStyle.textGreyColor,
    },
    tradeInfoValue: {
        color: commonStyle.textGreyColor,
    },
    tradeInfoValueGreen: {
        color: '#13B777',
    },
    tradeInfoValueRed: {
        color: '#F5465C',
    },
    confirmButtonContainer: {
        marginVertical: 20,
        alignItems: 'center',
    },
    confirmButton: {
        backgroundColor: commonStyle.green,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 5,
        width: '100%',
    },
    confirmButtonText: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    selectedAmountItem: {
        borderWidth: 2,
        borderColor: 'yellow'
    },
    selectedPeriodItem: {
        borderWidth: 2,
        borderColor: 'yellow'
    },
    selectedButton: {
        borderWidth: 2,
        borderColor: 'yellow'
    },
    bottomContainer: {
        // position: 'absolute',
        // bottom: 30,
        flexDirection: 'row',
        width: '100%',
        backgroundColor: commonStyle.bgColor,
    },
    button: {
        flex: 1,
        paddingVertical: 15,
        alignItems: 'center',
    },
    bottomText: {
        textAlign: 'center',
        color: commonStyle.textWhiteColor,
    },
    buttonLeft: {
        backgroundColor: commonStyle.green,
        paddingVertical: 10,
        width: '80%',
        justifyContent: "center",
        // paddingHorizontal: 60,
        borderRadius: 5,
        borderWidth: 2,
    },
    buttonRight: {
        backgroundColor: '#F5465C',
        width: '80%',
        paddingVertical: 10,
        // paddingHorizontal: 60,
        borderRadius: 5,
        borderWidth: 2,
    },
});

export default App;