import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Platform,ActivityIndicator } from 'react-native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style'
import { getAnnouncementdetail, getletterDetail } from '../api/apis';
import { WebView } from 'react-native-webview';

const MessageDetail = (props) => {
    const { t } = useTranslation();

    const [msgId, setMsgId] = useState(props.route.params.id);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [pushDate, setPushDate] = useState('');
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        if (props.route.params.type == 1) {
            getAnnouncement()
        } else {
            getletter()
        }
    }, []);

    const getAnnouncement = async () => {
        await getAnnouncementdetail(msgId).then(response => {
            // console.log(response)
            if (response.status == '1') {
                const data = response.Data.announcement[0]
                console.log(data)
                setTitle(data.title)
                setContent(data.txtContent)
                setPushDate(new Date(parseInt(data.time_year) * 1000).toLocaleString())
            }
        }).catch(error => {
            // console.error(error);
        });
    }

    const getletter = async () => {
        await getletterDetail(msgId).then(response => {
            // console.log(response)
            if (response.status == '1') {
                const data = response.Data.announcement[0]
                console.log(data)
                setTitle(data.title)
                setContent(data.content)
                setPushDate(new Date(parseInt(data.send_time) * 1000).toLocaleString())
            }
        }).catch(error => {
            // console.error(error);
        });
    }

    const htmlContent = `
    <!DOCTYPE html>
    <html>
    <head>
        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport">
        <style>
        body {background-color: #121420;padding: 0px;margin: 0px;}
       .contentContainer {
            width: 100%;
            height: 100%;
            background-color: #121420;
           
        }
       .title {
            font-size: 18px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 10px;}
        p {
            color: #fff  !important
        }
        span {
            color: #fff !important
        }
        #content {
            width: 100%;
            text-align: left;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            word-wrap: break-word;
            word-break: break-all;
            white-space: pre-wrap;
            margin-top: -30px;
        }
        </style>
    </head>
    <body>
        <div id="content">
          <p>${content}</p>
        </div>
    </body>
    </html>
    `;

    return (
        <><View style={styles.container}>
            <View style={styles.contentContainer}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.content}>{pushDate}</Text>
             
            </View>
        </View>
        {Platform.OS == 'web' && <iframe srcDoc={htmlContent} width="100%" height="100%" style={{ margin: 0, padding: 0, border: '0px' }} />}
        {Platform.OS != 'web' && <WebView
            originWhitelist={['*']}
            source={{ html: htmlContent }} />}
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        // alignItems: 'center',
        backgroundColor: commonStyle.bgColor,
    },
    contentContainer: {
        width: '90%',
    },
    title: {
        color: '#fff',
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 10,
    },
    content: {
        color: '#fff',
        fontSize: 16,
        marginBottom: 20
    },
});

export default MessageDetail;