import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, Image, Button, Alert,Clipboard } from 'react-native';
import { useTranslation } from 'react-i18next';
import { commonStyle } from '../components/style'
import { amountUnit, unitconversion ,validateAmount} from '../components/util'
import { getWebsiteInfo, getRechargetest } from '../api/apis';
import Toast from 'react-native-easy-toast'
import { showAlert } from '../components/showAlert';
import { useNavigation } from '@react-navigation/native';
import { AntDesign } from '@expo/vector-icons';

const RechargeWayUnion = (props) => {

    const { t, i18n } = useTranslation();
    const toastRef = useRef(null);
    const navigation = useNavigation();

    const [amount, setAmount] = useState('');
    const [amountExchangerate, setAmountExchangerate] = useState(0);

    const [unit, setUnit] = useState('');
    const [curr, setCurr] = useState('');
    const [exchangerate, setExchangerate] = useState('');
    const [extractedNumber, setExtractedNumber] = useState('');
    const [priceMax, setPriceMax] = useState('');
    const [priceMin, setPriceMin] = useState('');
    const [amFixed, setAmFixed] = useState([]);
    const [thirdName, setThirdName] = useState('');
    const [bankcade, setBankcade] = useState('');
    const [bankname, setBankname] = useState('');
    const [username, setUsername] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);


    // React.useLayoutEffect(() => {
    //     navigation.setOptions({
    //         title: props.route.params.rechargeWayUnionData.title,
    //         headerStyle: {
    //             backgroundColor: commonStyle.bgColor,
    //             borderBottomWidth: 0
    //         },
    //         headerTitleStyle: {
    //         },
    //     });
    // }, [navigation]);



    useEffect(() => {

        const rechargeWayUnion = props.route.params.rechargeWayUnionData

        setPriceMin(rechargeWayUnion.price_min)
        setPriceMax(rechargeWayUnion.price_max)

        setThirdName(rechargeWayUnion.third_name)
        setAmFixed(rechargeWayUnion.am_fixed)
        setBankcade(rechargeWayUnion.bankcade)
        setBankname(rechargeWayUnion.bankname)
        setUsername(rechargeWayUnion.username)

        init()
    }, []);

    const init = async () => {

        console.log('page:RechargeWayUnion')

        const response = await getWebsiteInfo();
        if (response.status == '1') {
            const curr = response.Data.curr
            // console.log(curr, i18n.language)
            setCurr(curr)
            setExchangerate(response.Data.exchangerate)

            var parts = response.Data.exchangerate.split(":");
            var extractedNumber = parts[1];
            setExtractedNumber(extractedNumber)

            const au = amountUnit(curr, i18n.language)
            // console.log(au.unit)
            // au.icon
            setUnit(au.unit)

        }
    }

    const handleAmoutClick = (value) => {
        setAmount(value)
    }

    const handleAmount = (text) => {
        if(!validateAmount(text)){
            return;
        }

        setAmount(text)
    }

    const handleSubmit = () => {
        // 使用正则表达式匹配金额，要求小数点后最多两位
        // const regex = /^\d+(\.\d{1,2})?$/;
        // if (!regex.test(amount.toString())) {
        //     toastRef.current.show(t('invalidAmount') + amount);
        //     return;
        // }

        if (Number(amount) >= Number(priceMin) && Number(amount) <= Number(priceMax)) {
            setAmountExchangerate(Number(extractedNumber) * Number(amount))
            setIsConfirm(true)
        } else {
            toastRef.current.show(t('invalidAmount') + amount);
            return;
        }
    }

    const handleSubmit2 = async () => {

        // 使用正则表达式匹配金额，要求小数点后最多两位
        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(amount.toString())) {
            toastRef.current.show(t('invalidAmount') + amount);
            return;
        }

        if (Number(amount) < Number(priceMin) && Number(amount) > Number(priceMax)) {
            toastRef.current.show(t('invalidAmount') + amount);
            return
        }

        var randomNumber = Math.floor(Math.random() * 90) + 10;

        const response = await getRechargetest(amountExchangerate + '.' + randomNumber, amount, exchangerate, thirdName, 4)

        if (response.status == '1') {
            showAlert(response.msg)
            navigation.goBack()
        } else {
            console.log(response)
            showAlert(t('fail'))
        }

        //{"Money_":"78230.02","Money":"5","Exchangerate":"IDR:15646","NickName":"moumouApay_BankTransferIDR0","id":"4"}
        // 
    }



    const handleCopy = (text) => {
        Clipboard.setString(text);
        toastRef.current.show(t('success'));
    }


    return (
        <View style={styles.container}>

            {isConfirm && <View style={{ flex: 1, alignItems: 'center', marginTop:30 }}>
                <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                    <Text style={styles.label}>{t('payee')}：{username}</Text>

                    <TouchableOpacity style={{marginTop:15}} onPress={() => handleCopy(username)}>
                        <AntDesign name="copy1" size={20} color="#828B9F" />
                    </TouchableOpacity>

                </View>
                <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                    <Text style={styles.label}>{t('accountNumber')}：{bankcade}</Text>

                    <TouchableOpacity style={{marginTop:15}} onPress={() => handleCopy(bankcade)}>
                        <AntDesign name="copy1" size={20} color="#828B9F" />
                    </TouchableOpacity>


                </View>
                <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                    <Text style={styles.label}>{t('bankBranch')}：{bankname}</Text>
                    <TouchableOpacity style={{marginTop:15}} onPress={() => handleCopy(bankname)}>
                        <AntDesign name="copy1" size={20} color="#828B9F" />
                    </TouchableOpacity>
                </View>
                <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                    <Text style={styles.label}>{t('recharge')}{t('amount')}：{amountExchangerate} {curr}</Text>
                    
                    <TouchableOpacity style={{marginTop:15}} onPress={() => handleCopy(amountExchangerate.toString())}>
                        <AntDesign name="copy1" size={20} color="#828B9F" />
                    </TouchableOpacity>

                </View>

                <TouchableOpacity style={styles.button} onPress={handleSubmit2}>
                    <Text style={styles.buttonText}>{t('theTransferHasBeenCompleted')}</Text>
                </TouchableOpacity>

            </View>}

            {!isConfirm && <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
                    {amFixed.map((item, index) => (
                        <React.Fragment key={index}>
                            <TouchableOpacity key={index} onPress={() => handleAmoutClick(item)}>
                                <View style={[styles.selectedPeriodItem, { justifyContent: 'center', width: 65, height: 50, padding: 10, borderWidth: 1, margin: 5, backgroundColor: commonStyle.green, borderRadius: 5 }]}>
                                    <Text style={{ color: '#fff', textAlign: 'center' }}>{item}</Text>
                                </View>
                            </TouchableOpacity>
                        </React.Fragment>
                    ))}
                    <Text></Text>
                </View>
                <View style={{ width: '98%' }}>
                    <Text style={[styles.text, { textAlign: 'left', marginLeft: 10, fontSize: 16 }]}>{t('recharge') + t('amount')}</Text>
                </View>
                <View style={{ flexDirection: 'row', width: '98%', borderRadius: 5, paddingHorizontal: 10, paddingVertical: 5 }}>
                    <TextInput
                        style={styles.input}
                        placeholder={t('pleaseEnterMoney') + `(${priceMin}-${priceMax})`}
                        onChangeText={handleAmount}
                        underlineColorAndroid="transparent"
                        value={String(amount)}
                        placeholderTextColor={commonStyle.green} />
                    <Text style={styles.label}>{curr}</Text>

                </View>
                <TouchableOpacity style={styles.button} onPress={handleSubmit}>
                    <Text style={styles.buttonText}>{t('confirm')}</Text>
                </TouchableOpacity>

                <View style={{ marginTop: 20 }}>

                    <Text style={{ color: 'gray' }}>{t('tips')}</Text>
                    {/* <Text style={{ color: 'red' }}>{t('rechargeTip.1')}{curr} = 1:{extractedNumber}</Text> */}
                    <Text style={{ color: 'gray' }}>{t('rechargeTip.3')}</Text>
                    <Text style={{ color: 'gray' }}>{t('rechargeTip.4')}</Text>
                </View>
            </View>}

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                textStyle={{ color: '#fff' }}
            />
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        paddingHorizontal: 15
        // height: '99%'
    },
    text: {
        color: '#fff'
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
        marginRight: 10,
        marginLeft: 5
    },
    input: {
        // placeholderTextColor:'#707070',
        // color: '#707070',
        marginBottom: 15,
        marginTop: 0,
        height: 40,
        borderWidth: 1,
        borderColor: commonStyle.green,
        borderRadius: 5,
        padding: 5,
        color: commonStyle.green,
        backgroundColor: '#222430',
        flex: 1
        // borderBottomColor: 'transparent'
    },
    button: {
        // backgroundColor: '#2C3445',
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 20,
        borderRadius: 5,
        width: '90%'
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
        fontSize: 16
    },
})

export default RechargeWayUnion;