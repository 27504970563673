import React, { useState, useEffect, useRef ,forwardRef} from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Platform, ActivityIndicator } from 'react-native';
import { EvilIcons, Ionicons } from '@expo/vector-icons';
import { useNavigation, useFocusEffect, CommonActions, navigationState } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import ActiveTabItem from '../components/activeTabItem'
import { commonStyle } from '../components/style';
import { getHuobiPrice, getKline, getSymbolPrice, getKline2All } from '../api/apis';
import { WebView } from 'react-native-webview';
import { navigateRequireLogin, virtual, isLoggedIn } from '../components/util';
import DraggableButton from '../components/draggableButton';

// https://www.coinbase.com/
const App = forwardRef((props, ref) => {

    const { i18n, t } = useTranslation();
    const navigation = useNavigation();

    const timeOptionTab = { '1m': { t: '1min', s: 144 }, '5m': { t: '5min', s: 288 }, '15m': { t: '15min', s: 96 }, '30m': { t: '30min', s: 48 }, '1h': { t: '60min', s: 12 }, '4h': { t: '4hour', s: 6 }, '1d': { t: '1day', s: 1 } };
    const [activeOption, setActiveOption] = useState('1m'); 
    const [currency, setCurrency] = useState(props.route?.params?.currency ? props.route?.params?.currency : 'BTC');
    const [amount, setAmount] = useState('--');
    const [percentage, setPercentage] = useState('--');
    const [low, setLow] = useState('--');
    const [high, setHigh] = useState('--');
    const [vol, setVol] = useState('--');
    const [dataList2, setDataList2] = useState(false);
    const [webViewLoad, setWebViewLoad] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [headerRightButton, setHeaderRightButton] = useState('none');

    const webViewRef = useRef(null);
    const iframeRef = useRef(null);

    // const handleHeaderRightClick = () => {
    //     if (headerRightButton == 'none') {
    //         setHeaderRightButton('block')
    //     } else {
    //         setHeaderRightButton('none')
    //     }
    // };

    useFocusEffect(
        React.useCallback(() => {
            updateKline()
            const intervalId = setInterval(updateKline, 5000);

            return () => {

                // 在组件失去焦点时清除定时器
                clearInterval(intervalId);
            };
        }, [activeOption,currency])
    );


    useEffect(() => {

        isLoggedIn(navigation)
        init()
        kline2All()
        // updateDataListPrice()

        return () => {

            if (webViewRef.current) {
                webViewRef.current = null;
            }
        };
    }, [currency]);

    const init = async () => {

        const huobiPrice = await getHuobiPrice(currency);
        if (huobiPrice) {
            setAmount(huobiPrice)
        }

        const kline = await getKline(currency, '1day', 2);
        if (kline) {
            // console.log('kline')
            setPercentage(kline.changePercentage)
            setLow(kline.klineData[0].low)
            setHigh(kline.klineData[0].high)
            setVol(kline.klineData[0].vol.toFixed(2))
        }

    }

    const updateKline = async () => {
        const tabData = timeOptionTab[activeOption]
        // console.log('activeOption',activeOption)
        // console.log(tabData)
        handleTabChange(activeOption, tabData.t, tabData.s, false)
    }

    const kline2All = async () => {
        const kline2All = await getKline2All();
        if (kline2All) {
            setDataList2(kline2All);
        }
    };

    const handleTabChange = (key, value, size, isButtonClick = true) => {
        console.log(key, value, size) // 15m 15min 96

        if (isButtonClick) {
            setActiveOption(key);
        }

        const line = key == 'line' ? 1 : 0;

        if (webViewRef.current && Platform.OS != 'web') {
            webViewRef.current.injectJavaScript(`tabChange("${value}","${size}","${line}");`);
        }
        if (Platform.OS == 'web') {
            // console.log('触发iframe方法')
            const iframe = iframeRef.current;
            iframe.contentWindow.postMessage({ line: line, type: "callFunction", data: value }, "*");
        }
    };

    useEffect(() => {
        if (Platform.OS === 'web') {
            window.addEventListener('message', handleIframeChange);
            return () => {
                window.removeEventListener('message', handleIframeChange);
            };
        }

    }, []);


    const handleIframeChange = (event) => {
        // {"id":1714031820,"open":64026.09,"close":63991.39,"low":63991.39,"high":64026.09,"amount":0.07769,"vol":4972.85843958,"count":9}
        // console.log(event.data.close)
        if (event?.data?.close) {
            setAmount(event.data.close)
        }

    };

    const goToExchangeScreen = (currency) => {
        setHeaderRightButton('none')
        navigation.replace('Exchange', { currency: currency });
    }

    const goToExchangeScreen2 = (currency) => {
        navigation.navigate('Exchange', { currency: currency })
    }

    const goToContractScreen = (currency) => {
        navigation.navigate('Contract', { currency: currency })
    }

    // 暴露方法
    React.useImperativeHandle(ref, () => ({
        currencyUpdate,
    }));

    const currencyUpdate = (currencyVal) => {
        setCurrency(currencyVal)
        setAmount('--')
        setPercentage('--')
        setLow('--')
        setHigh('--')
        setVol('--')
    }
        
    const htmlContent = `
    <!DOCTYPE html>
    <html>
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">
        <meta name="apple-mobile-web-app-capable" content="yes">
        <meta name="apple-mobile-web-app-status-bar-style" content="#B4E6FB">
    </head>
    <style type="text/css">
        body { margin: 0; padding: 0; }
        .Kline-div {
            position: fixed;
            width: 100%;
            bottom: 0;
            top: 0;
        }
        .Kline-div .K-line {
            height: 75%;
            width: 100%;
        }
        .loading {
            display: none; 
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
        .loading .spinner {
            border: 4px solid rgba(255, 255, 255, 0.3);
            border-top: 4px solid #13B777;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    </style>
    <body>
        <button id="os" style="display:none">${Platform.OS}</button>
        <div class="currency" style="display:none">${currency.toLowerCase().trim()}usdt</div>
        <div id="Kline-div" class="Kline-div">
            <div id="m-line" class="m-line"></div>
            <div id="k-content" class="K-line"></div>
        </div>
        <div class="loading">
            <div class="spinner"></div>
        </div>
        <script src="https://cdn.bootcdn.net/ajax/libs/jquery/3.7.1/jquery.js"></script>
        <script src="https://cdn.bootcss.com/echarts/3.7.1/echarts.min.js"></script>
        <script>
            var bgColor = "#121420";
            var upColor = "#F9293E";
            var downColor = "#00aa3b";

            var ma5Color = "#39afe6";
            var ma10Color = "#da6ee8";
            var ma20Color = "#ffab42";
            var ma30Color = "#00940b";

            function splitData(rawData) {
                var datas = [];
                var times = [];
                var vols = [];
                for (var i = 0; i < rawData.length; i++) {
                    datas.push(rawData[i]);
                    times.push(rawData[i].splice(0, 1)[0]);
                    vols.push(rawData[i][4]);
                }
                return { datas: datas, times: times, vols: vols };
            }

            function calculateMA(dayCount, data) {
                var result = [];
                for (var i = 0, len = data.times.length; i < len; i++) {
                    if (i < dayCount) {
                        result.push('-');
                        continue;
                    }
                    var sum = 0;
                    for (var j = 0; j < dayCount; j++) {
                        sum += data.datas[i - j][1];
                    }
                    result.push((sum / dayCount).toFixed(2));
                }
                return result;
            }

            var calcEMA = function (n, data, field) {
                var i, l, ema, a;
                a = 2 / (n + 1);
                if (field) {
                    ema = [data[0][field]];
                    for (i = 1, l = data.length; i < l; i++) {
                        ema.push((a * data[i][field] + (1 - a) * ema[i - 1]).toFixed(2));
                    }
                } else {
                    ema = [data[0]];
                    for (i = 1, l = data.length; i < l; i++) {
                        ema.push((a * data[i] + (1 - a) * ema[i - 1]).toFixed(3));
                    }
                }
                return ema;
            };

            var calcDIF = function (short, long, data, field) {
                var i, l, dif, emaShort, emaLong;
                dif = [];
                emaShort = calcEMA(short, data, field);
                emaLong = calcEMA(long, data, field);
                for (i = 0, l = data.length; i < l; i++) {
                    dif.push((emaShort[i] - emaLong[i]).toFixed(3));
                }
                return dif;
            };

            var calcDEA = function (mid, dif) {
                return calcEMA(mid, dif);
            };

            var calcMACD = function (short, long, mid, data, field) {
                var i, l, dif, dea, macd, result;
                result = {};
                macd = [];
                dif = calcDIF(short, long, data, field);
                dea = calcDEA(mid, dif);
                for (i = 0, l = data.length; i < l; i++) {
                    macd.push(((dif[i] - dea[i]) * 2).toFixed(3));
                }
                result.dif = dif;
                result.dea = dea;
                result.macd = macd;
                return result;
            };

            function initKOption(cdata) {
                var data = splitData(cdata);
                var macd = calcMACD(12, 26, 9, data.datas, 1);

                var lastDataIndex = data.datas.length - 1;
                var lastData = data.datas[lastDataIndex];

                return {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    legend: [
                        {
                            icon: 'rect',
                            type: 'scroll',
                            itemWidth: 14,
                            itemHeight: 2,
                            animation: true,
                            textStyle: {
                                fontSize: 12,
                                color: '#0e99e2'
                            },
                            pageIconColor: '#0e99e2',
                            data: [
                           
                                { name: 'MA5', textStyle: { color: '#39afe6' } },
                                { name: 'MA10', textStyle: { color: '#da6ee8' } },
                                { name: 'MA20', textStyle: { color: '#ffab42' } },
                                { name: 'MA30', textStyle: { color: '#00940b' } },
                                { name: 'DIF', textStyle: { color: '#39afe6' } },
                                { name: 'DEA', textStyle: { color: '#da6ee8' } }
                            ],
                            top: '-1%',
                            left: 'center'
                        },
                        // {
                        //     icon: 'rect',
                        //     type: 'scroll',
                        //     itemWidth: 14,
                        //     itemHeight: 2,
                        //     animation: true,
                        //     textStyle: {
                        //         fontSize: 12,
                        //         color: '#0e99e2'
                        //     },
                        //     pageIconColor: '#0e99e2',
                        //     data: [
                        //         { name: 'Volumn', textStyle: { color: '#ffab42' } }
                        //     ],
                        //     top: '2%',
                        //     left: 'center'
                        // },
                        {
                            icon: 'rect',
                            type: 'scroll',
                            itemWidth: 14,
                            itemHeight: 2,
                            animation: true,
                            textStyle: {
                                fontSize: 12,
                                color: '#0e99e2'
                            },
                            pageIconColor: '#0e99e2',
                            data: [
                                // { name: 'Volumn', textStyle: { color: '#ffab42' } },
                                // { name: 'MACD', textStyle: { color: '#ffab42' } },
                                // { name: 'DIF', textStyle: { color: '#39afe6' } },
                                // { name: 'DEA', textStyle: { color: '#da6ee8' } }
                            ],
                            top: '4%',
                            left: 'center'
                        }
                    ],
                    axisPointer: {
                        show: true
                    },
                    color: [ma5Color, ma10Color, ma20Color, ma30Color],
                    grid: [{
                        id: 'gd1',
                        left: '1%',
                        right: '60',
                        height: '50%',
                        top: '5%'
                    }, {
                        left: '1%',
                        right: '60',
                        top: '60%',
                        height: '15%'
                    }, {
                        left: '1%',
                        right: '60',
                        top: '80%',
                        height: '14%'
                    }],
                    xAxis: [{
                        type: 'category',
                        data: data.times,
                        scale: true,
                        boundaryGap: false,
                        axisLine: {
                            onZero: false
                        },
                        axisLabel: {
                            show: true,
                            color: '#9b9da9',
                            fontSize: 10
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#181a23'
                            }
                        },
                        splitNumber: 20,
                        min: 'dataMin',
                        max: 'dataMax'
                    }, {
                        type: 'category',
                        gridIndex: 1,
                        data: data.times,
                        axisLabel: {
                            color: '#9b9da9',
                            fontSize: 10
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#181a23'
                            }
                        },
                    }, {
                        type: 'category',
                        gridIndex: 2,
                        data: data.times,
                        axisLabel: {
                            // 显示第三张图表的x轴时间
                            show: true,
                            color: '#9b9da9',
                            fontSize: 10
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#181a23'
                            }
                        },
                        splitLine: {
                            // 添加背景网格
                            show: true,
                            lineStyle: {
                                color: '#181a23'
                            }
                        }
                    }],
                    yAxis: [{
                        position: 'right', // 从左到右显示刻度
                        scale: true,
                        z: 4,
                        axisLabel: {
                            color: '#c7c7c7',
                            inside: false,
                            position: 'right' 
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#181a23'
                            }
                        },
                    }, {
                        position: 'right', // 从左到右显示刻度
                        gridIndex: 1,
                        splitNumber: 3,
                        z: 4,
                        axisLine: {
                            onZero: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: '#181a23'
                            }
                        },
                        axisLabel: {
                            color: '#c7c7c7',
                            inside: false,
                            fontSize: 8
                        },
                    }, {
                        position: 'right', // 从左到右显示刻度
                        z: 4,
                        gridIndex: 2,
                        splitNumber: 4,
                        axisLine: {
                            onZero: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            // 添加背景网格
                            show: true,
                            lineStyle: {
                                color: '#181a23'
                            }
                        },
                        axisLabel: {
                            color: '#c7c7c7',
                            inside: false,
                            fontSize: 8
                        },
                    }],
                    dataZoom: [{
                        type: 'inside',
                        xAxisIndex: [0, 1, 2],
                        // start: 100,
                        // end: 10,
                        throttle: 10,
                        top: '94%',
                        height: '6%',
                        left: '10%',
                        right: '10%',
                        borderColor: '#696969',
                        textStyle: {
                            color: '#dcdcdc'
                        },
                        handleSize: '90%',
                        handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                        dataBackground: {
                            lineStyle: {
                                color: '#fff'
                            },
                            areaStyle: {
                                color: '#696969'
                            }
                        }
                    }],
                    animation: true,
                    backgroundColor: bgColor,
                    blendMode: 'source-over',
                    series: [{
                        name: 'Kline',
                        type: 'candlestick',
                        data: data.datas,
                        barWidth: '55%',
                        large: true,
                        largeThreshold: 100,
                        itemStyle: {
                            normal: {
                                color: upColor,
                                color0: downColor,
                                borderColor: upColor,
                                borderColor0: downColor,
                            }
                        },
                        markLine: {
                            symbol: 'none',
                            data: [
                                {
                                    name: 'Last Close',
                                    xAxis: data.times[lastDataIndex],
                                    yAxis: lastData[1],
                                    lineStyle: {
                                        type: 'dotted',
                                        color: '#ffab42'
                                    },
                                    label: {
                                        show: true,
                                        // 将数值标签位置改为左边的y轴
                                        formatter: function (params) {
                                            return 'Last Close: ' + params.value.toFixed(2);
                                        },
                                        position: 'insideEndTop',
                                        color: '#ffab42',
                                        fontSize: 12
                                    }
                                }
                            ]
                        }
                    }, {
                        name: 'MA5',
                        type: 'line',
                        data: calculateMA(5, data),
                        smooth: true,
                        symbol: "none",
                        lineStyle: {
                            normal: {
                                opacity: 0.8,
                                color: '#39afe6',
                                width: 1
                            }
                        },
                    }, {
                        name: 'MA10',
                        type: 'line',
                        data: calculateMA(10, data),
                        smooth: true,
                        symbol: "none",
                        lineStyle: {
                            normal: {
                                opacity: 0.8,
                                color: '#da6ee8',
                                width: 1
                            }
                        }
                    }, {
                        name: 'MA20',
                        type: 'line',
                        data: calculateMA(20, data),
                        smooth: true,
                        symbol: "none",
                        lineStyle: {
                            opacity: 0.8,
                            width: 1,
                            color: ma20Color
                        }
                    }, {
                        name: 'MA30',
                        type: 'line',
                        data: calculateMA(30, data),
                        smooth: true,
                        symbol: "none",
                        lineStyle: {
                            normal: {
                                opacity: 0.8,
                                width: 1,
                                color: ma30Color
                            }
                        }
                    }, {
                        name: 'Volumn',
                        type: 'bar',
                        xAxisIndex: 1,
                        yAxisIndex: 1,
                        data: data.vols,
                        barWidth: '60%',
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    var colorList;
                                    if (data.datas[params.dataIndex][1] > data.datas[params.dataIndex][0]) {
                                        colorList = upColor;
                                    } else {
                                        colorList = downColor;
                                    }
                                    return colorList;
                                },
                            }
                        }
                    }, {
                        name: 'MACD',
                        type: 'bar',
                        xAxisIndex: 2,
                        yAxisIndex: 2,
                        data: macd.macd,
                        barWidth: '40%',
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    var colorList;
                                    if (params.data >= 0) {
                                        colorList = upColor;
                                    } else {
                                        colorList = downColor;
                                    }
                                    return colorList;
                                },
                            }
                        }
                    }, {
                        name: 'DIF',
                        type: 'line',
                        symbol: "none",
                        xAxisIndex: 2,
                        yAxisIndex: 2,
                        data: macd.dif,
                        lineStyle: {
                            normal: {
                                color: '#39afe6',
                                width: 1
                            }
                        }
                    }, {
                        name: 'DEA',
                        type: 'line',
                        symbol: "none",
                        xAxisIndex: 2,
                        yAxisIndex: 2,
                        data: macd.dea,
                        lineStyle: {
                            normal: {
                                opacity: 0.8,
                                color: '#da6ee8',
                                width: 1
                            }
                        }
                    }]
                };
                
                
            }

            $('.loading').fadeIn();
            $('#chart').hide()

            function myFunction(data,line) {
                //alert("方法被调用了！参数是："+data);
                tabChange(data,200,line)
            }
        
            window.addEventListener("message", function (event) {
         
                if (event.data.type === "callFunction") {
                  myFunction(event.data.data,event.data.line);
                }
            });

            // 更新图表数据
            function tabChange(tab,size,line) {
                
                const symbol = $('.currency').text()

                let newKlineData = []
                
                // 显示加载动画
                // $('.loading').fadeIn();
                // $('#chart').hide()

                $.ajax({
                    url: 'https://api-aws.huobi.pro/market/history/kline',
                    type: 'GET',
                    data: {
                        symbol: symbol,
                        period: tab,
                        size: 70
                    },
                    success: function (data) {
                        // 加载完成后隐藏加载动画
                        $('.loading').fadeOut();
                        $('#chart').show()
                        // 请求成功 data 包含响应数据
                        if (data.status == 'ok') {
                            // console.log(data.data)

                      
                            const res = data.data

                    
                            for (i = 0; i < res.length; i++) {
            
                                const timestamp = res[i].id * 1000; // 转换为毫秒级时间戳
                                const date = new Date(timestamp);
                                const year = date.getFullYear();
                                const month = date.getMonth() + 1;
                                const day = date.getDate();
                                const hours = date.getHours();
                                const minutes = date.getMinutes();
                                const seconds = date.getSeconds();

                                const formattedTime =   String(month).padStart(2, '0') + '/' + String(day).padStart(2, '0') + '-' + String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0') ;

                                //newKlineData.push([formattedTime, res[i].open, res[i].close, '-', '-', res[i].low, res[i].high, res[i].vol, res[i].count,'-'])
                                newKlineData.push([formattedTime, res[i].open, res[i].close, res[i].low, res[i].high, res[i].vol, res[i].count])

                            }

                            let os = $('#os').text();
                            
                            if(os == 'web'){
                                parent.postMessage(res[0], '*');
                            }else{
                                window.ReactNativeWebView.postMessage(JSON.stringify(res[0]))
                            }
                            
                          
                            // initChart(newKlineData.reverse(),false,line,res[0].close);
                            fetchDataAndUpdateChart(newKlineData.reverse());
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        // $('.loading').fadeOut();
                        // $('#chart').show()
                        console.log('Error: ' + textStatus);
                    }
                });

            }


            function fetchDataAndUpdateChart(kdata) {
                        
                //数据模型 time0 open1 close2 min3 max4 vol5
                //['2015-10-19',18.56,18.25,18.19,18.56,55.00,0,-0.00,0.08,0.09] 
                // var kdata = [
                //     ['2015-10-16', 18.4, 18.58, 18.33, 18.79, 67.00, 1, 0.04, 0.11, 0.09],
                //     ['2015-10-19', 18.56, 18.25, 18.19, 18.56, 55.00, 0, -0.00, 0.08, 0.09],
                //     ['2015-10-20', 18.3, 18.22, 18.05, 18.41, 37.00, 0, 0.01, 0.09, 0.09],
                // ]
                
                kChart.setOption(initKOption(kdata));
            }

           

            var kChart = echarts.init(document.getElementById('k-content'));
            window.addEventListener('resize', handleResize);
            function handleResize() {
                kChart.resize();
            }

        </script>
    </body>
    </html>

    `;

    const BottomButtons = () => {
        return (
            <View style={styles.bottomContainer}>

                <TouchableOpacity style={styles.button} onPress={() => goToExchangeScreen2(currency)}>
                    <View style={styles.buttonLeft}>
                        <Text style={styles.bottomText}>{t('exchange')}</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.button]} onPress={() => goToContractScreen(currency)}>
                    <View style={styles.buttonRight}>
                        <Text style={styles.bottomText}>{t('contract')}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        );
    };

    return (
        <>
            <View style={styles.container}>
                {/* <DraggableButton /> */}
                <View style={{ display: headerRightButton, backgroundColor: 'rgba(18, 20, 32, 1)', borderRadius: 3, borderWidth: 1, borderColor: 'gray', width: 140, position: 'absolute', top: 0, right: 15, zIndex: 999 }}>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        {Object.entries(virtual()).map(([symbol, name]) => (
                            <View key={name}>
                                <TouchableOpacity onPress={() => goToExchangeScreen(symbol)}>
                                    <View style={{ width: 68 }}>
                                        <Text style={{ textAlign: 'center', color: '#fff', marginVertical: 10 }}>{symbol}</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        ))}
                    </View>
                </View>

                <Text style={styles.currencyText}>{currency}</Text>
                <View style={styles.infoContainer}>
                    <View style={{ flex: 1, justifyContent: 'flex-start', marginLeft: 10 }}>
                        <Text style={[styles.infoText, { color: percentage > 0 ? '#0ECB81' : '#F5465C' }]}>{amount}</Text>
                        <Text style={[styles.infoSubtext, { color: percentage > 0 ? '#0ECB81' : '#F5465C' }]}>{percentage}{percentage != '--' && '%'}</Text>
                    </View>
                    <View style={styles.infoColumn}>
                        <InfoRow label={t('low')} value={low} />
                        <InfoRow label={t('high')} value={high} />
                        <InfoRow label={'24h' + t('vol')} value={vol} />
                    </View>
                </View>
                <View style={styles.timeContainer}>
                    <View><Text style={styles.timeText}>{t('time')}</Text></View>

                    {Object.keys(timeOptionTab).map((key) => (
                        <TouchableOpacity key={key} onPress={() => handleTabChange(key, timeOptionTab[key].t, timeOptionTab[key].s)}>
                            <View>
                                <Text style={styles.timeText}>{key} </Text>
                                {key == activeOption && <ActiveTabItem />}
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
                <View style={styles.chartContainer}>

                    {loadingMore && <ActivityIndicator color={commonStyle.green} style={{ marginTop: 20 }} />}

                    {Platform.OS == 'web' && <iframe srcDoc={htmlContent} ref={iframeRef} width="100%" height="100%" style={{ border: '0px' }} />}
                    {Platform.OS != 'web' && <WebView
                        style={{ flex: 1, backgroundColor: commonStyle.bgColor }}
                        ref={webViewRef}
                        onMessage={(event) => {
                            // console.log(event.nativeEvent.data);
                            // console.log(typeof event.nativeEvent.data)
                            let data = JSON.parse(event.nativeEvent.data);
                            // console.log(data)
                            setAmount(data.close)
                        }}
                        source={{ html: htmlContent }}
                        // onLoadEnd={() => setWebViewLoad(true)}
                        onLoad={() => {
                            setWebViewLoad(true)
                        }}
                    />}
                </View>
                {!props?.hideButtom && <BottomButtons />}
            </View>
        </>
    );
});

const InfoRow = ({ label, value }) => (
    <View style={styles.infoRow}>
        <Text style={styles.infoLabel}>{label}</Text>
        <Text style={styles.infoValue}>{value}</Text>
    </View>
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: commonStyle.bgColor,
        // alignItems: 'center',
    },
    currencyText: {
        color: commonStyle.textWhiteColor,
        fontSize: 16,
        marginTop: 5,
        textAlign: 'center'
    },
    infoContainer: {
        width: '90%',
        marginTop: 5,
        // paddingBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    infoColumn: {
        flex: 1,
        flexDirection: 'column',
    },
    infoText: {
        fontSize: 20,
        color: '#2CF793',
    },
    infoSubtext: {
        fontSize: 15,
        color: '#2CF793',
    },
    infoRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    infoLabel: {
        color: '#828B9F',
        marginRight: 10
    },
    infoValue: {
        color: '#fff',
    },
    timeContainer: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5,
        paddingBottom: 10,
        marginLeft: 10
    },
    timeText: {
        color: '#fff',
        textAlign: 'center'
    },
    chartContainer: {
        flex: 1,
        // justifyContent: 'center',
        // alignItems: 'center',
        // width: '90%',
    },
    chart: {
        height: 200,
        width: '100%',
        padding: 20,
    },
    bottomContainer: {
        position: 'absolute',
        bottom: 10,
        flexDirection: 'row',
        width: '100%',
        backgroundColor: commonStyle.bgColor,
    },
    button: {
        flex: 1,
        paddingVertical: 15,
        alignItems: 'center',
    },
    buttonWrapper: {
        backgroundColor: commonStyle.green,
        paddingVertical: 10,
        width: '80%',
        justifyContent: 'center',
        borderRadius: 10,
    },
    bottomText: {
        textAlign: 'center',
        color: commonStyle.textWhiteColor,
    },
    buttonLeft: {
        backgroundColor: commonStyle.green,
        paddingVertical: 10,
        width: '80%',
        justifyContent: "center",
        // paddingHorizontal: 60,
        borderRadius: 5
    },
    buttonRight: {
        // backgroundColor: '#F5465C',
        backgroundColor: commonStyle.green,
        width: '80%',
        paddingVertical: 10,
        // paddingHorizontal: 60,
        borderRadius: 5
    },
});

export default App;